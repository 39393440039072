import { DropdownWithFilter } from "../DropdownWithFilter";
import { cell } from "./Cell";
import { Button } from "react-bootstrap";

export function CellSelector(props) {
 const { sessions, filteredSessions, setFilteredSessions, matrix, setMatrix, coordinates, isEdit } = props;

 return (
  <>
   {matrix && (
    <>
     <DropdownWithFilter
      title={"Add session"}
      items={filteredSessions.map(filteredSession => {
       return {
        text: filteredSession.title,
        object: filteredSession,
       };
      })}
      filterFn={text => setFilteredSessions(sessions.filter(filteredSession => filteredSession.title.toLowerCase().includes(text)))}
      onClickFn={session => {
       let modifiedMatrix = matrix.slice();
       modifiedMatrix[coordinates.x][coordinates.y] = cell(session, coordinates, "session");
       setMatrix(modifiedMatrix);
      }}
     />
     <Button
      onClick={() => {
       let modifiedMatrix = matrix.slice();
       modifiedMatrix[coordinates.x][coordinates.y] = cell("Text here", coordinates, "text");
       setMatrix(modifiedMatrix);
      }}>
      Add text
     </Button>
    </>
   )}
  </>
 );
}
