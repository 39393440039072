import { Button, Col, Form, Row, Badge, Container, ButtonGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { FormCard } from "./FormCard";
import { FormSubmitButton } from "./FormSubmitButton";
import { dbApi } from "../services/firebase";
import { useAuthContext } from "../contexts/authContext";
import { AdminOnly } from "./AdminOnly";
import "../styles/SessionEdit.css";
import { DropdownWithFilter } from "./DropdownWithFilter";
import "./SessionEditColors.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

function handleSubmit(session, sessionId, navigate, secondPresenterExists) {
  const title = session.title;
  const subtitle = session.subtitle;
  if (title.trim() === subtitle.trim()) {
    alert("Title and subtitle cannot be the same.");
    return;
  }
  session.presenters[0] = session.presenters[0].id;
  if (!secondPresenterExists) {
    session.presenters = [session.presenters[0]];
  } else if (session.presenters[1] && session.presenters[1].firstName) {
    session.presenters[1] = session.presenters[1].id;
  }
  if (session.shortDescription) {
    session.shortDescription = session.shortDescription.replaceAll(
      "\n",
      "%;;;%"
    );
  }
  if (session.content) {
    session.content = session.content.replaceAll("\n", "%;;;%");
  }
  if (session.outlineOrTimetable) {
    session.outlineOrTimetable = session.outlineOrTimetable.replaceAll(
      "\n",
      "%;;;%"
    );
  }
  dbApi.editSession(sessionId, session).then(() => {
    navigate("/session/" + sessionId);
  });
}

export function SessionEdit() {
  const stateSessionSubmissionPromise = useMemo(
    () => dbApi.getSessionSubmissionState(),
    []
  );
  const [stateSessionSubmission, setStateSessionSubmission] = useState(false);
  const editActiveStatePromise = useMemo(() => dbApi.getSessionEditState(), []);
  const [editActiveState, setEditActiveState] = useState(false);

  const { currentPresenter } = useAuthContext();
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [filteredPresenters, setFilteredPresenters] = useState([]);
  const [filteredPresenters2, setFilteredPresenters2] = useState([]);
  const presentersPromise = useMemo(() => dbApi.getAllPresenters(), []);
  const [allPresenters, setAllPresenters] = useState();
  const [secondPresenterExists, setSecondPresenterExists] = useState();
  const [secondPresenterExistsStartValue, setSecondPresenterExistsStartValue] = useState();
  const [ExperienceState, setGetExperienceState] = useState();


  //char limit
  const maxCharLimits = { title: 70, subtitle: 80, desc: 500 };

  const [session, setSession] = useState({
    presenters: ["", ""],
    title: "",
    subtitle: "",
    content: "",
    shortDescription: "",
    xpFactor: "",
    state: "",
    sessionGoal: "",
    sessionType: "",
    maxParticipants: "",
    topic: "",
    laptopsRequired: false,
    otherLimitations: "",
    duration: "",
    roomSetup: "",
    intendedAudience: "",
    materialsNeeded: "",
    experienceLevel: "",
    outlineOrTimetable: "",
    materialDescription: "",
    materialUrl: "",
  });
  const sessionPromise = useMemo(() => dbApi.getSession(id), [id]);
  const navigate = useNavigate();

  const handleDelete = async (sessionId) => {
    try {
      await dbApi.deleteSession(sessionId);
      // Redirect the user back to the sessions page
      navigate("/sessions");
    } catch (error) {}
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Are you sure?</Popover.Header>
      <Popover.Body>
        There is <strong>no recovering</strong> this session.
        <Button variant="danger" onClick={() => handleDelete(id)}>
          Delete
        </Button>
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    stateSessionSubmissionPromise.then((promisedState) => {
      setStateSessionSubmission(promisedState);
      console.log("sessions:" + promisedState);
    });
  }, [stateSessionSubmissionPromise]);

  useEffect(() => {
    editActiveStatePromise.then((promisedState) => {
      setEditActiveState(promisedState);
      console.log("editActiveState: " + promisedState);
    });
  });

  useEffect(() => {
    presentersPromise?.then((promisedPresenters) => {
      if (!promisedPresenters) return;
      if (promisedPresenters.length === 0) return;
      setAllPresenters(promisedPresenters);
      setFilteredPresenters(allPresenters);
      setFilteredPresenters2(allPresenters);
    });
  }, [presentersPromise, allPresenters]);

  useEffect(() => {
    if (!allPresenters) return;
    sessionPromise.then((promisedSession) => {
      setLoaded(true);
      if (promisedSession.presenters) {
        if (promisedSession.presenters[0]) {
          promisedSession.presenters[0] = allPresenters.filter(
            (p) => p.id === promisedSession.presenters[0]
          )[0];
        }
        if (promisedSession.presenters[1]) {
          promisedSession.presenters[1] = allPresenters.filter(
            (p) => p.id === promisedSession.presenters[1]
          )[0];
          if (
            promisedSession.presenters[1] &&
            promisedSession.presenters[1].firstName
          ) {
            setSecondPresenterExists(true);
            setSecondPresenterExistsStartValue(true);
          } else {
            setSecondPresenterExists(false);
            setSecondPresenterExistsStartValue(false);
          }
        } else {
          setSecondPresenterExists(false);
          setSecondPresenterExistsStartValue(false);
        }
      }
      setSession(promisedSession);
    });
  }, [sessionPromise, allPresenters]);
  

  useEffect(() => {
    dbApi.getExperienceState().then((promisedExperience) => {
      setGetExperienceState(promisedExperience);
    }
    );
 }, []);
  
  return (
    <>
      {loaded &&
        currentPresenter &&
        (((currentPresenter.id === session.presenters[0]?.id ||
          currentPresenter.id === session.presenters[1]?.id) &&
          editActiveState) ||
          currentPresenter.role === "admin") &&
        session && (
          <FormCard title={"Edit Session"} className={"mt-5"}>
            <Row>
              <Form
                className="row g-3 m-1"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(session, id, navigate, secondPresenterExists);
                }}
              >
                <Col md={3} className={"border-end"}>
                  {currentPresenter.role === "admin" ? (
                    <>
                      <Form.Group className="col-12">
                        <Form.Label>First presenter:</Form.Label>
                        <DropdownWithFilter
                          items={filteredPresenters.map((filteredPresenter) => {
                            return {
                              text:
                                filteredPresenter.firstName +
                                " " +
                                filteredPresenter.lastName,
                              object: filteredPresenter,
                            };
                          })}
                          filterFn={(text) =>
                            setFilteredPresenters(
                              allPresenters.filter((filteredPresenter) =>
                                (
                                  filteredPresenter.firstName +
                                  filteredPresenter.lastName
                                )
                                  .toUpperCase()
                                  .includes(text.toUpperCase())
                              )
                            )
                          }
                          onClickFn={(presenter) => {
                            setSession({
                              ...session,
                              presenters: [presenter, session.presenters[1]],
                            });
                          }}
                          title={
                            session.presenters[0] &&
                            session.presenters[0].firstName
                              ? session.presenters[0].firstName +
                                " " +
                                session.presenters[0].lastName
                              : "First Presenter"
                          }
                          className={
                            "button-standard session-edit-presenter-dropdown"
                          }
                        />
                      </Form.Group>
                    </>
                  ) : (
                    <Form.Group className="col-12">
                      <Form.Label>First presenter:</Form.Label>
                      <Form.Control
                        className={"session-input-field"}
                        type="text"
                        value={
                          session.presenters[0]
                            ? session.presenters[0].firstName +
                              " " +
                              session.presenters[0].lastName
                            : ""
                        }
                        disabled
                      />
                    </Form.Group>
                  )}

                  {currentPresenter.role === "admin" ||
                  (stateSessionSubmission &&
                    !secondPresenterExistsStartValue) ? (
                    <>
                      <Form.Group
                        style={{ marginTop: "1em" }}
                        className="col-12"
                      >
                        <Form.Label>Second presenter:</Form.Label>
                        {secondPresenterExists ? (
                          <Form.Check
                            style={{ marginLeft: "0.2em" }}
                            className={"checkbox d-inline"}
                            type="checkbox"
                            checked
                            onChange={() =>
                              setSecondPresenterExists(!secondPresenterExists)
                            }
                          />
                        ) : (
                          <Form.Check
                            style={{ marginLeft: "0.2em" }}
                            className={"checkbox d-inline"}
                            type="checkbox"
                            onChange={() =>
                              setSecondPresenterExists(!secondPresenterExists)
                            }
                          />
                        )}
                      </Form.Group>
                      {secondPresenterExists && (
                        <Form.Group className="col-12">
                          {/*<Form.Label>Second presenter:</Form.Label>*/}
                          <DropdownWithFilter
                            items={filteredPresenters2
                              .filter((p) => p.id !== session.presenters[0].id)
                              .map((filteredPresenter) => {
                                return {
                                  text:
                                    filteredPresenter.firstName +
                                    " " +
                                    filteredPresenter.lastName,
                                  object: filteredPresenter,
                                };
                              })}
                            filterFn={(text) =>
                              setFilteredPresenters2(
                                allPresenters.filter((filteredPresenter) =>
                                  (
                                    filteredPresenter.firstName +
                                    filteredPresenter.lastName
                                  )
                                    .toUpperCase()
                                    .includes(text.toUpperCase())
                                )
                              )
                            }
                            onClickFn={(presenter) => {
                              setSession({
                                ...session,
                                presenters: [session.presenters[0], presenter],
                              });
                            }}
                            title={
                              session.presenters[1] &&
                              session.presenters[1].firstName
                                ? session.presenters[1].firstName +
                                  " " +
                                  session.presenters[1].lastName
                                : "Second Presenter"
                            }
                            className={
                              "button-standard session-edit-presenter-dropdown"
                            }
                            classname={"inputFieldSessionEdit"}
                          />
                        </Form.Group>
                      )}
                    </>
                  ) : (
                    <Form.Group className="col-12">
                      <Form.Label>Second presenter:</Form.Label>
                      <Form.Control
                        className={"session-input-field"}
                        type="text"
                        value={
                          session.presenters[1] &&
                          session.presenters[1].firstName
                            ? session.presenters[1].firstName +
                              " " +
                              session.presenters[1].lastName
                            : ""
                        }
                        disabled
                      />
                    </Form.Group>
                  )}
                  <Form.Group className="col-12">
                    <Form.Label>Title:</Form.Label>
                    <Form.Control
                      className={"session-input-field"}
                      type="text"
                      maxLength={maxCharLimits.title}
                      value={session.title}
                      onChange={(e) =>
                        setSession({ ...session, title: e.target.value })
                      }
                    />
                    <Badge className="mt-3" bg={`danger`}>
                      {session.title.length}/{maxCharLimits.title}
                    </Badge>
                  </Form.Group>
                  <Form.Group className="col-12">
                    <Form.Label>Subtitle:</Form.Label>
                    <Form.Control
                      className={"session-input-field"}
                      type="text"
                      maxLength={maxCharLimits.subtitle}
                      value={session.subtitle}
                      placeholder={
                        "sometimes your title is catchy but doesn't describe the content of your session. A subtitle can help. Keep short!"
                      }
                      onChange={(e) =>
                        setSession({ ...session, subtitle: e.target.value })
                      }
                    />
                    <Badge className="mt-3" bg={`danger`}>
                      {session.subtitle.length}/{maxCharLimits.subtitle}
                    </Badge>
                  </Form.Group>
                  {session && (
                    <Form.Group className="col-12">
                      <Form.Label>Topic:</Form.Label>
                      <Form.Select
                        className={"session-input-field"}
                        value={session.topic}
                        onChange={(e) =>
                          setSession({ ...session, topic: e.target.value })
                        }
                      >
                        {!session.topic && <option value={""}></option>}
                        <option
                          style={{ backgroundColor: "#c7FFB1" }}
                          value="Technology and Technique"
                        >
                          Technology and Technique
                        </option>
                        <option
                          style={{ backgroundColor: "#D6F9FF" }}
                          value="Customer and Planning"
                        >
                          Customer and Planning
                        </option>
                        <option
                          style={{ backgroundColor: "#FFFF99" }}
                          value="Intro's and Cases"
                        >
                          Intro's and Cases
                        </option>
                        <option
                          style={{ backgroundColor: "#FED28E" }}
                          value="Process and Improvement"
                        >
                          Process and Improvement
                        </option>
                        <option
                          style={{ backgroundColor: "#FEC9DC" }}
                          value="Team and Individual"
                        >
                          Team and Individual
                        </option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  )}
                </Col>
                <Col md={4} className={"border-end"}>
                  <Row>
                    <Form.Group className="col-md-6">
                      <Form.Label>
                        XP factor: <b>{session.xpFactor}</b>
                      </Form.Label>
                      <Form.Control
                        style={{ border: 0 }}
                        className={"shadow-none form-range slider-standard"}
                        min={0}
                        max={10}
                        type="range"
                        value={session.xpFactor}
                        onChange={(e) =>
                          setSession({ ...session, xpFactor: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-md-6">
                      <Form.Label>Duration:</Form.Label>
                      <Form.Select
                        className={"session-input-field"}
                        value={session.duration}
                        onChange={(e) =>
                          setSession({ ...session, duration: e.target.value })
                        }
                      >
                        {!session.duration && <option value={""}></option>}
                        <option value="30 min">30 min</option>
                        <option value="60 min">60 min</option>
                        <option value="90 min">90 min</option>
                        <option value="120 min">120 min</option>
                        <option value="120 min">150 min</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="col-md-6">
                      <Form.Label>State:</Form.Label>
                      {currentPresenter.role === "admin" ? (
                        <Form.Select
                          className={"session-input-field"}
                          value={session.state}
                          onChange={(e) =>
                            setSession({ ...session, state: e.target.value })
                          }
                        >
                          {!session.state && <option value={""}></option>}
                          <option value="Draft">Draft</option>
                          <option value="Cancelled">Cancelled</option>
                          <option value="Confirmed">Confirmed</option>
                        </Form.Select>
                      ) : (
                        <Form.Control
                          className={"session-input-field"}
                          value={session.state}
                          disabled
                        />
                      )}
                    </Form.Group>
               {ExperienceState &&  <Form.Group className="col-md-6">
                      <Form.Label>Experience Level:</Form.Label>
                      <Form.Select
                        className={"session-input-field"}
                        value={session.experienceLevel}
                        onChange={(e) =>
                          setSession({
                            ...session,
                            experienceLevel: e.target.value,
                          })
                        }
                      >
                        {!session.experienceLevel && (
                          <option value={""}></option>
                        )}
                        <option value="Never heard of it is OK">Never heard of it is OK</option>
                        <option value="Some knowledge is expected">Some knowledge is expected</option>
                        <option value="Advanced knowledge is expected">Advanced knowledge is expected</option>
                      </Form.Select>
                    </Form.Group>}
                
                  </Row>
                  <Form.Group className="col-12">
                    <Form.Label>Session goal:</Form.Label>
                    <Form.Control
                      className={"session-input-field"}
                      type="text"
                      value={session.sessionGoal}
                      placeholder={
                        "what do you expect participants to get out of this session?"
                      }
                      onChange={(e) =>
                        setSession({ ...session, sessionGoal: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Row>
                    <Form.Group className="col-6">
                      <Form.Label>Session Type:</Form.Label>
                      <Form.Select
                        className={"session-input-field"}
                        value={session.sessionType}
                        onChange={(e) =>
                          setSession({
                            ...session,
                            sessionType: e.target.value,
                          })
                        }
                      >
                        {!session.sessionType && <option value={""}></option>}
                        <option value="hands on">hands on</option>
                        <option value="discovery session">
                          discovery session
                        </option>
                        <option value="experiential learning session">
                          experiential learning session
                        </option>
                        <option value="short experience report (30 min)">
                          short experience report (30 min)
                        </option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="col-6">
                      <Form.Label>Max participants:</Form.Label>
                      <Form.Control
                        className={"session-input-field"}
                        type="number"
                        value={session.maxParticipants}
                        onChange={(e) =>
                          setSession({
                            ...session,
                            maxParticipants: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group className="col-12">
                    <Form.Label>Intended audience:</Form.Label>
                    <Form.Control
                      className={"session-input-field"}
                      type="text"
                      value={session.intendedAudience}
                      placeholder={"who will benefit from this session?"}
                      onChange={(e) =>
                        setSession({
                          ...session,
                          intendedAudience: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Row>
                    <Form.Group className="col-9">
                      <Form.Label>Other Limitations:</Form.Label>
                      <Form.Control
                        className={"session-input-field"}
                        type="text"
                        value={session.otherLimitations}
                        onChange={(e) =>
                          setSession({
                            ...session,
                            otherLimitations: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group className="col-3">
                      <Form.Label>Laptops required:</Form.Label>
                      {session.laptopsRequired ? (
                        <Form.Check
                          className={"checkbox"}
                          type="checkbox"
                          checked
                          onChange={() =>
                            setSession({
                              ...session,
                              laptopsRequired: !session.laptopsRequired,
                            })
                          }
                        />
                      ) : (
                        <Form.Check
                          className={"checkbox"}
                          type="checkbox"
                          onChange={() =>
                            setSession({
                              ...session,
                              laptopsRequired: !session.laptopsRequired,
                            })
                          }
                        />
                      )}
                    </Form.Group>
                  </Row>
                  <Form.Group className="col-12">
                    <Form.Label>Room Setup:</Form.Label>
                    <Form.Select
                      className={"session-input-field"}
                      value={session.roomSetup}
                      onChange={(e) =>
                        setSession({ ...session, roomSetup: e.target.value })
                      }
                    >
                      {!session.roomSetup && <option value={""}></option>}
                      <option value="Groups (cabaret)">Groups (cabaret)</option>
                      <option value="theater">theater</option>
                      <option value="classroom">classroom</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="col-12">
                    <Form.Label>Materials needed:</Form.Label>
                    <Form.Control
                      className={"session-input-field"}
                      type="text"
                      value={session.materialsNeeded}
                      placeholder={
                        "extra stuff you need the organisers to arrange for your session"
                      }
                      onChange={(e) =>
                        setSession({
                          ...session,
                          materialsNeeded: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="col-12">
                    <Form.Label>Material description:</Form.Label>
                    <Form.Control
                      className={"session-input-field"}
                      type="text"
                      value={session.materialDescription}
                      placeholder={
                        "Descriptive text for the link to your session materials"
                      }
                      onChange={(e) =>
                        setSession({
                          ...session,
                          materialDescription: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="col-12">
                    <Form.Label>Material url:</Form.Label>
                    <Form.Control
                      className={"session-input-field"}
                      type="url"
                      value={session.materialUrl}
                      placeholder={"Absolute URL to your session materials"}
                      onChange={(e) =>
                        setSession({ ...session, materialUrl: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Form.Group className="input-group">
                  <Container>
                    <Form.Label className="col-12">
                      Short Description:
                    </Form.Label>
                    <Form.Control
                      style={{ borderRadius: "5px" }}
                      className={"textAreaSessionEdit"}
                      as="textarea"
                      value={
                        session.shortDescription
                          ? session.shortDescription.replaceAll("%;;;%", "\n")
                          : ""
                      }
                      placeholder={
                        'will appear on the "Program Cards" on the "Program Board" at the conference. It should be a teaser.'
                      }
                      maxLength={maxCharLimits.desc}
                      onChange={(e) =>
                        setSession({
                          ...session,
                          shortDescription: e.target.value,
                        })
                      }
                    />
                    <Badge className="mt-3" bg={`danger`}>
                      {session.shortDescription.length}/{maxCharLimits.desc}
                    </Badge>
                  </Container>
                </Form.Group>
                <Form.Group className="input-group">
                  <Container>
                    <Form.Label className="col-12">Description:</Form.Label>
                    <Form.Control
                      style={{ borderRadius: "5px" }}
                      className={"textAreaSessionEdit"}
                      as="textarea"
                      value={
                        session.content
                          ? session.content.replaceAll("%;;;%", "\n")
                          : ""
                      }
                      placeholder={
                        "this will appear on the website, and they will also be displayed on the wall during the conference -> public description"
                      }
                      onChange={(e) =>
                        setSession({ ...session, content: e.target.value })
                      }
                    />
                  </Container>
                </Form.Group>
                <Form.Group className="col-12">
                  <Container>
                    <Form.Label>Outline or timetable:</Form.Label>
                    <Form.Control
                      style={{ borderRadius: "5px" }}
                      className={"textAreaSessionEdit"}
                      as="textarea"
                      value={session.outlineOrTimetable}
                      placeholder={
                        "this section contains a detailed outline of your session. It will not be viewable by the public, only by reviewers"
                      }
                      onChange={(e) =>
                        setSession({
                          ...session,
                          outlineOrTimetable: e.target.value,
                        })
                      }
                    />
                  </Container>
                </Form.Group>
                <Container>
          <ButtonGroup  style={{  marginTop: "1em"}}>
      <Container>
        <FormSubmitButton className={"button-standard"}>
          Save 
        </FormSubmitButton>
      </Container>
      <Container >
        {currentPresenter?.role === "admin" && (
          <OverlayTrigger
            rootClose={true}
            trigger="click"
            placement="right"
            overlay={popover}
          >
            <Button variant="outline-danger">Delete</Button>
          </OverlayTrigger>
        )}
      </Container>
                </ButtonGroup>
                </Container>
              </Form>
            </Row>
          </FormCard>
        )}
      {currentPresenter &&
        !(
          currentPresenter.id === session.presenters[0]?.id ||
          currentPresenter.id === session.presenters[1]?.id ||
          currentPresenter.role === "admin"
        ) &&
        loaded && <AdminOnly>Something went wrong</AdminOnly>}
    </>
  );
}
