import { useEffect, useMemo, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { FormSubmitButton } from "./FormSubmitButton";
import { dbApi } from "../services/firebase";
import { useAuthContext } from "../contexts/authContext";
import "./CommentsColors.css";

export function Comments(props) {
    const { reviewId } = props;
    const [comments, setComments] = useState([]);
    const commentsPromise = useMemo(() => dbApi.getCommentsOnReview(reviewId), [reviewId]);
    const [stateReview, setStateReview] = useState(true);
    const stateReviewPromise = useMemo(() => dbApi.getReviewsAndCommentsState(), []);



    useEffect(() => {
        stateReviewPromise.then((promisedState) => setStateReview(promisedState));
      }, [stateReviewPromise]);

    useEffect(() => {
        commentsPromise.then((promisedComments) => {
            if (promisedComments.length === 0) return;
            const presenters = promisedComments.map((promisedComment) => {
                return promisedComment.commentBy;
            });
            dbApi.getPresentersByIds(presenters).then((presenters) => {
                const completeComments = promisedComments.map((promisedComment) => {
                    presenters.forEach((presenter) => {
                        if (presenter.id === promisedComment.commentBy) promisedComment.commentBy = presenter;
                    });
                    return promisedComment;
                });
                setComments(completeComments);
            });
        });
    }, [commentsPromise]);

    return (
        <div>
            <div style={{
                borderTop: "#00000033 solid 2px", marginTop: "1em", padding: 0, marginLeft: "1em", marginRight: "1em"
            }} />
            <div style={{ marginLeft: "1em", marginRight: "1em", fontSize: "1.2em", fontWeight: "bold" }}>Comments:</div>
            <div style={{ marginLeft: "3em", marginRight: "3em" }}>
                {comments.sort(sortComments).map(c => <Comment key={c.id} comment={c} />)}
                {stateReview &&  <WriteComment reviewId={reviewId} />}
            </div>
        </div>
    );
}

function Comment(props) {
    const { comment } = props;
    const [commentContent, setCommentContent] = useState(undefined);
    const [editMode, setEditMode] = useState(false);
    const { currentPresenter } = useAuthContext();

    return (!editMode ? <div>
        <div>Comment by {comment.commentBy?.firstName + ' ' + comment.commentBy?.lastName}</div>
        <div className={"review-comment"} style={{
            padding: "0.5%", borderRadius: "5px"
        }}>{!comment.commentContent.includes("%;;;%") ? comment.commentContent : comment.commentContent.split("%;;;%").map((l, index) =>
            <div key={l + index}>{l}</div>)}</div>
        <Row style={{ marginLeft: '0.2em' }}>
            {
                currentPresenter.id === comment.commentBy.id ?
                    <button className="edit-comment-button btn d-inline col-2"
                        style={{
                            marginTop: "0.5em",
                            height: "2em",
                            paddingTop: "0.1em"
                        }} onClick={() => setEditMode(true)}>Edit your comment</button>
                    : <div className={"col-2"}></div>}
            <div className={"comment-created col-10 text-end"}
                style={{ marginBottom: 0, marginTop: "0", fontSize: "0.9em" }}>Comment
                Created: {comment.dateCreated ? <>
                    {(new Date(comment.dateCreated.seconds * 1000)).getDate()}/{(new Date(comment.dateCreated.seconds * 1000)).getMonth() + 1}/{(new Date(comment.dateCreated.seconds * 1000)).getFullYear()} {(new Date(comment.dateCreated.seconds * 1000)).getHours() < 10 ? "0" + (new Date(comment.dateCreated.seconds * 1000)).getHours() : (new Date(comment.dateCreated.seconds * 1000)).getHours()}:{(new Date(comment.dateCreated.seconds * 1000)).getMinutes() < 10 ? "0" + (new Date(comment.dateCreated.seconds * 1000)).getMinutes() : (new Date(comment.dateCreated.seconds * 1000)).getMinutes()}</> : "not defined"}
                {comment.dateModified ? <>
                    , Comment
                    Modified: {(new Date(comment.dateModified.seconds * 1000)).getDate()}/{(new Date(comment.dateModified.seconds * 1000)).getMonth() + 1}/{(new Date(comment.dateModified.seconds * 1000)).getFullYear()} {(new Date(comment.dateModified.seconds * 1000)).getHours() < 10 ? "0" + (new Date(comment.dateModified.seconds * 1000)).getHours() : (new Date(comment.dateModified.seconds * 1000)).getHours()}:{(new Date(comment.dateModified.seconds * 1000)).getMinutes() < 10 ? "0" + (new Date(comment.dateModified.seconds * 1000)).getMinutes() : (new Date(comment.dateModified.seconds * 1000)).getMinutes()}</> : <></>}
                </div>
                
        </Row>
        <div className={"comment-separator"} style={{ marginTop: "0.5em", padding: 0 }} />
    </div> : <Form className="row g-3" onSubmit={(e) => {
        e.preventDefault();
        handleSubmitEdit(comment.id, commentContent);
        setEditMode(false)
    }}>
        <Form.Group className="col-12">
            <Form.Label>Edit your comment:</Form.Label>
            <Form.Control defaultValue={comment.commentContent.replaceAll("%;;;%", "\n")}
                className={"textAreaReview"} as={"textarea"}
                onChange={(e) => setCommentContent(e.target.value)} required />
        </Form.Group>
        <FormSubmitButton className={"button-standard comment-save-button"} style={{ marginLeft: "2em", marginRight: "2em" }}>Save comment</FormSubmitButton>
    </Form>);
}


function WriteComment(props) {
    const { reviewId } = props;
    const [commentContent, setCommentContent] = useState('');
    const [posted, setPosted] = useState(false);
    const { currentPresenter } = useAuthContext();

    const [sessionId, setSessionId] = useState('');
    const [reviewmadeby, setReviewMadeBy] = useState('');

    useEffect(() => {
        dbApi.getReview(reviewId).then(review => {
            setSessionId(review.sessionDocID);
            setReviewMadeBy(review.reviewedBy);
        }).catch(error => console.error("Error fetching session ID:", error));
    }, [reviewId]);

    return (<div>
        <Form className="row g-3" onSubmit={(e) => {
            setPosted(true);
            e.preventDefault();
            handleSubmit(currentPresenter.id, reviewId, commentContent, setCommentContent, sessionId, reviewmadeby);
        }}>
            <Form.Group className="col-12">
                <Form.Label style={{ fontSize: "1em" }}>Comment on this review:</Form.Label>
                <Form.Control value={commentContent} className={"textAreaReview"} as={"textarea"}
                    onChange={(e) => setCommentContent(e.target.value)} required />
            </Form.Group>
            {posted ? <FormSubmitButton className={"button-standard comment-post-button"} style={{ marginLeft: "2em", marginRight: "2em" }} disabled>Post
                comment</FormSubmitButton> :
                <FormSubmitButton className={"button-standard comment-post-button"} style={{ marginLeft: "2em", marginRight: "2em" }}>Post comment</FormSubmitButton>}
        </Form>
    </div>);
}

function handleSubmit(submitterId, reviewId, commentContent, setCommentContent, sessionId, reviewmadeby) {
    dbApi.submitComment(submitterId, reviewId, commentContent.replaceAll("\n", "%;;;%"), sessionId, reviewmadeby)
        .then(() => {
            window.location.reload();
            setCommentContent('');
        })
        .catch(error => console.error("Error submitting comment:", error));
}

function sortComments(a, b) {
    if (a.dateCreated === undefined || a.dateCreated === null) {
        return 1;
    }
    if (b.dateCreated === undefined || b.dateCreated === null) {
        return -1;
    }
    if (a.dateCreated < b.dateCreated) {
        return -1;
    }
    if (a.dateCreated > b.dateCreated) {
        return 1;
    }
    return 0;
}

function handleSubmitEdit(commentId, content) {
    dbApi.editComment(commentId, content.replaceAll("\n", "%;;;%")).then(() => window.location.reload());
}
