import { Container } from "react-bootstrap";
import { dbApi } from "../services/firebase";
import { useState, useMemo, useEffect } from "react";

export function StatisticsPage() {
 const reviewsPromise = useMemo(() => dbApi.getReviewsOfNotCancelledSessions(), []);
 const sessionsPromise = useMemo(() => dbApi.getAllSessionsThatAreNotCancelled(), []);
 const presentersPromise = useMemo(() => dbApi.getAllPresenters(), []);
 const [allReviews, setAllReviews] = useState([]);
 const [allSessions, setAllSessions] = useState([]);
 const [allPresenters, setAllPresenters] = useState([]);
 const [numberOfReviewedSessions, setNumberOfReviewedSessions] = useState();
 const [numberOfPresentersWhoReviewed, setNumberOfPresentersWhoReviewed] = useState();
 const [numberOfUpdatedAfterReview, setNumberOfUpdatedAfterReview] = useState();
 const token = "82ca0a98-7cad-45f7-9fb1-41e4087a81b3";

 const filteredPresenters = allPresenters.filter(presenter => {
  return !presenter.profilePicture.includes(token);
 });

 const count = filteredPresenters.length;
 const percentage = ((count / allPresenters.length) * 100).toFixed(1);
 const twitterCount = allPresenters.filter(p => p.twitter).length;
 const twitterPercentage = ((twitterCount / allPresenters.length) * 100).toFixed(1);
 const linkedinCount = allPresenters.filter(p => p.linkedin).length;
 const linkedinPercentage = ((linkedinCount / allPresenters.length) * 100).toFixed(1);
 const instagramCount = allPresenters.filter(p => p.instagram).length;
 const instagramPercentage = ((instagramCount / allPresenters.length) * 100).toFixed(1);

 useEffect(() => {
  let reviewedSessions = [];
  let presentersWhoReviewed = [];
  reviewsPromise.then(promisedReview => {
   setAllReviews(promisedReview);
   promisedReview.forEach(r => {
    if (!reviewedSessions.includes(r.sessionDocID)) {
     reviewedSessions.push(r.sessionDocID);
    }
    if (!presentersWhoReviewed.includes(r.reviewedBy)) {
     presentersWhoReviewed.push(r.reviewedBy);
    }
   });
   setNumberOfReviewedSessions(reviewedSessions.length);
   setNumberOfPresentersWhoReviewed(presentersWhoReviewed.length);
  });
 }, [reviewsPromise]);

 useEffect(() => {
  sessionsPromise.then(promisedSession => {
   promisedSession.map(s => {
    s.reviews = [];
    allReviews.forEach(r => {
     if (s.id === r.sessionDocID) {
      s.reviews.push(r);
     }
    });
    return s;
   });
   setAllSessions(promisedSession.filter(s => s.verified));
   let updatedAfterReview = 0;
   promisedSession
    .filter(s => s.verified)
    .forEach(s => {
     if (s.reviews && s.reviews.length > 0) {
      let updated = false;
      s.reviews.forEach(r => {
       if (r.dateCreated < s.modified) {
        updated = true;
       }
      });
      if (updated) {
       updatedAfterReview++;
      }
     }
    });
   setNumberOfUpdatedAfterReview(updatedAfterReview);
  });
 }, [sessionsPromise, allReviews]);

 useEffect(() => {
  presentersPromise.then(promisedPresenter => {
   setAllPresenters(promisedPresenter.filter(p => p.verified));
  });
 }, [presentersPromise]);

 return (
     <Container style={{maxWidth: "75%", margin: "auto", marginTop:"1em"}}>
   <h1 style={{ marginBottom: 0 }}>Statistics</h1>
   <h2 style={{ marginTop: "0.8em" }}>Reviews</h2>
   <div>
    <b>Sessions reviewed: </b>
    {numberOfReviewedSessions}
    <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
     ({((numberOfReviewedSessions / allSessions.length) * 100).toFixed(1)}% of all {allSessions.length} verified sessions)
    </div>
   </div>
   <div>
    <b>Total number of reviews: </b>
    {allReviews.length}
   </div>
   <div>
    <b>Done by: </b>
    {numberOfPresentersWhoReviewed} presenters
    <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
     ({((numberOfPresentersWhoReviewed / allPresenters.length) * 100).toFixed(1)}% of all {allPresenters.length} verified presenters)
    </div>
   </div>
   <h2 style={{ marginTop: "0.8em" }}>Session completeness</h2>
   <div style={{ fontSize: "1.3em", marginTop: 0, marginBottom: 0 }}>Verified sessions that have:</div>
   <div>
    <b>Description: </b>
    {allSessions.filter(s => s.content).length}
    <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
     ({((allSessions.filter(s => s.content).length / allSessions.length) * 100).toFixed(1)}% of all {allSessions.length} verified sessions)
    </div>
   </div>
   <div>
    <b>Outline or timetable: </b>
    {allSessions.filter(s => s.outlineOrTimetable).length}
    <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
     ({((allSessions.filter(s => s.outlineOrTimetable).length / allSessions.length) * 100).toFixed(1)}% of all {allSessions.length} verified sessions)
    </div>
   </div>
   <div>
    <b>Most important fields filled in: </b>
    {allSessions.filter(s => s.content && s.sessionType && s.duration && s.sessionGoal && s.outlineOrTimetable).length}
    <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
     ({((allSessions.filter(s => s.content && s.sessionType && s.duration && s.sessionGoal && s.outlineOrTimetable).length / allSessions.length) * 100).toFixed(1)}% of all {allSessions.length} verified sessions) (fields: description, session type, duration, session goal, outline or timetable)
    </div>
   </div>
   <div>
    <b>updated after last review: </b>
    {numberOfUpdatedAfterReview}
    <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
     ({numberOfUpdatedAfterReview / numberOfReviewedSessions ? ((numberOfUpdatedAfterReview / numberOfReviewedSessions) * 100).toFixed(1) : 0}% of all {numberOfReviewedSessions} reviewed sessions)
    </div>
   </div>
   <h2 style={{ marginTop: "0.8em" }}>Presenter completeness</h2>
   <div style={{ fontSize: "1.3em", marginTop: 0, marginBottom: 0 }}>Verified presenters that have:</div>
   <div>
    <b>Bio: </b>
    {allPresenters.filter(p => p.bio).length}
    <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
     ({((allPresenters.filter(p => p.bio).length / allPresenters.length) * 100).toFixed(1)}% of all {allPresenters.length} verified presenters)
    </div>
   </div>
   <div>
    <b>Profile picture: </b>
    {count}
    <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
     ({percentage}% of all {allPresenters.length} verified presenters)
    </div>
   </div>
   <div>
    <div>
     <b> x (Twitter): </b>
     {twitterCount}
     <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
      ({twitterPercentage}% of all {allPresenters.length} verified presenters)
     </div>
    </div>
    <div>
     <b>LinkedIn: </b>
     {linkedinCount}
     <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
      ({linkedinPercentage}% of all {allPresenters.length} verified presenters)
     </div>
    </div>
    <div>
     <b>Instagram: </b>
     {instagramCount}
     <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
      ({instagramPercentage}% of all {allPresenters.length} verified presenters)
     </div>
    </div>
   </div>
   <div>
    <b>Website: </b>
    {allPresenters.filter(p => p.website).length}
    <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
     ({((allPresenters.filter(p => p.website).length / allPresenters.length) * 100).toFixed(1)}% of all {allPresenters.length} verified presenters)
    </div>
   </div>
   <div>
    <b>Completed presenters: </b>
    {allPresenters.filter(p => p.bio && p.profilePicture  && ( p.website || p.instagram || p.linkedin || p.twitter)).length}
    <div className={"d-inline"} style={{ fontSize: "0.87em", marginLeft: "0.8em" }}>
     ({((allPresenters.filter(p => p.bio && p.profilePicture  && ( p.website || p.instagram || p.linkedin || p.twitter)).length / allPresenters.length) * 100).toFixed(1)}% of all {allPresenters.length} verified presenters) (presenters who have a bio, profile picture, website and one of these: ( X(twitter), instagram, linkedin)
    </div>
   </div>
  </Container>
 );
}
