import {Card, Col, Container, Image, ProgressBar, Row} from "react-bootstrap";
import {useAuthContext} from "../contexts/authContext";
import {Link} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {dbApi} from "../services/firebase";
import {ReviewList} from "./ReviewList";
import {useNavigate} from "react-router-dom";
import "./DashBoard.css";

export function DashBoard() {
    const {user, currentPresenter} = useAuthContext();

    const [reviews, setReviews] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [votes, setVotes] = useState([]);
    // eslint-disable-next-line
    const reviewsPromise = useMemo(() => dbApi.getReviewsOfPresenter(currentPresenter?.id), [currentPresenter]);
    const sessionsPromise = useMemo(() => dbApi.getSessionsOfPresenter(currentPresenter?.id), [currentPresenter]);
    const votesPromise = useMemo(() => dbApi.getVotesFromPresenter(currentPresenter?.id), [currentPresenter]);
    const dashboardPromise = useMemo(() => dbApi.getDashboard(), []);
    const [dashboard, setDashboard] = useState(undefined);

     
    useEffect(() => {
        dashboardPromise?.then((promisedDashboard) => {
            if (!promisedDashboard) return;
            if (promisedDashboard.length === 0) return;
            setDashboard(promisedDashboard);
        });
    }, [dashboardPromise]);
       


    const navigate=useNavigate()

    useEffect(() => {
        votesPromise?.then((promisedVotes) => {
            if (!promisedVotes) return;
            if (promisedVotes.length === 0) return;
            setVotes(promisedVotes);
        });
    }  , [votesPromise]);



    useEffect(() => {
        reviewsPromise?.then((promisedReviews) => {
            if (!promisedReviews || promisedReviews.length === 0) return;
    
            const batchSize = 10; // number of presenters to fetch in one batch (DO NOT SET HIGHER THAN 10 firebase won't allow it!)
            const presenterIds = promisedReviews.map((promisedReview) => promisedReview.reviewedBy);
            const batches = [];
            
           
            for (let i = 0; i < presenterIds.length; i += batchSize) {
                const batch = presenterIds.slice(i, i + batchSize);
                batches.push(dbApi.getPresentersByIds(batch));
            }
            
           
            Promise.all(batches)
                .then((batchedPresenters) => {
                    const presenters = batchedPresenters.flat();
                    const completeReviews = promisedReviews.map((promisedReview) => {
                        const presenter = presenters.find((p) => p.id === promisedReview.reviewedBy);
                        if (presenter) promisedReview.reviewedBy = presenter;
                        return promisedReview;
                    });
                    setReviews(completeReviews);
                })
                .catch((error) => {
                    console.error("Error fetching presenters: ", error);
                });
        });
    }, [reviewsPromise]);
    

    useEffect(() => {
        sessionsPromise?.then((promisedSessions) => {
            if (!promisedSessions) return;
            if (promisedSessions.length === 0) return;
            setSessions(promisedSessions);
        });
    }, [sessionsPromise]);

    return (
        <>
            {user && currentPresenter && reviews &&
                <div>
                    <Container className={"container-fluid"} >
                        <Row className={"d-flex"} style={{marginTop:"2em"}}>
                            {dashboard && (
                                <Col xs className={"p-2 mt-5"} style={{minWidth:"50%"}}>
                                {currentPresenter.firstName && currentPresenter.lastName ?
                                    <h1 style={{marginLeft: "-0.1em"}}>Welcome {currentPresenter.firstName + " " + currentPresenter.lastName}!</h1> :
                                    <h1 style={{ marginLeft: "-0.1em" }}>{dashboard.title ||""}</h1>}
                                <h1 style={{ marginLeft: "-0.1em" }}>{dashboard.title ||""} </h1>
                                <p>{dashboard.text}</p>
                             
                            </Col>
                            )}
                            <Col className="p-2">
                                {dashboard && (
                                    <Image
                                        src={dashboard.url || ""} 
                                        className="img-fluid"
                                        style={{ minWidth: '280px', maxWidth:"280px", marginTop:"2em", marginBottom:"3em" }}
                                        alt="Dashboard Image"
                                    />
                                )}
                            </Col>
                            
                        </Row>
                    </Container>
                    <Container >
                        <Row >
                            {/*profile section*/}
                            <Col style={{ justifyContent:"space-evenly",alignItems:"stretch" }}>
                                <Card style={{width: '18rem', marginBottom: "0.5em"}}>
                                    <Card.Body>
                                        {getProfileProgress()===100?
                                            <>
                                                <Card.Title>Complete your profile</Card.Title>
                                                <Card.Text>
                                                    Fill in your bio, add a profile image, website link, or  X (Twitter) ID to
                                                    complete
                                                    your profile.
                                                    <button onClick={()=>navigate("/profile/"+currentPresenter.id)} className={"btn button-standard dashboard-profile-button"} style={{width:"100%",marginTop:"0.8em"}}>Go to your profile</button>
                                                </Card.Text>
                                            </>:
                                             <>
                                                <Card.Title>You completed your profile</Card.Title>
                                                <Card.Text>
                                                You filled in your profile information. This will help people to connect with your and get an idea of who you are.
                                                <button onClick={()=>navigate("/profile/"+currentPresenter.id)} className={"btn button-standard dashboard-profile-button"} style={{width:"100%",marginTop:"0.8em"}}>Go to your profile</button>
                                                </Card.Text>
                                            </>
                                        }
                                        <ProgressBar className={"progress-bar-standard dashboard-profile-progress-bar"} now={getProfileProgress(currentPresenter)}/>
                                        {/*<Button variant="primary">Go somewhere</Button>*/}
                                    </Card.Body>
                                </Card>
                            </Col>
                            {/*session section*/}
                            <Col style={{ justifyContent:"space-evenly",alignItems:"stretch" }}>
                                <Card style={{width: '18rem', marginBottom: "0.5em"}}>
                                    <Card.Body>
                                        <Card.Title>{sessions.length>1?"Sessions you are involved in":"Session you are involved in"}</Card.Title>
                                        <Card.Text>

                                        </Card.Text>
                                    </Card.Body>
                                    {sessions ? <>{sessions.map((s, index) => (
                                        <SessionListRow session={s} key={s.id+index}/>
                                    ))}
                                    </> : <>no sessions</>}
                                </Card>
                            </Col>
                            {/*review section*/}
                            {reviews &&
                                <Col style={{ justifyContent:"space-evenly",alignItems:"stretch" }}>
                                    <Card style={{width: '18rem', marginBottom: "0.5em"}}>
                                        <Card.Body>
                                            <Card.Title>{reviews.length === 1 ? "You wrote 1 review" : "You wrote " + reviews.length + " reviews"}</Card.Title>
                                            <Card.Text>
                                                {reviews.length > 0 ?
                                                    "Thank you for helping our other participants. You are a valuable member of our community." :
                                                    "Help our other participants by writing reviews!"
                                                }
                                            </Card.Text>
                                            <ProgressBar now={reviews.length < 6 ? reviews.length * 20 : 100} className={"progress-bar-standard dashboard-review-progress-bar"}/>
                                        </Card.Body>
                                        {reviews ? <ReviewList key={"reviewList"} reviews={reviews}/> : <></>}
                                    </Card>

                                </Col>}
                             {/*votes section*/}
                             <Col style={{ justifyContent:"space-evenly",alignItems:"stretch" }}>
                                <Card style={{width: '18rem', marginBottom: "0.5em"}}>
                                    <Card.Body>
                                        <Card.Title>{votes.length>1?"Sessions you have voted for":"Session you have voted for"}</Card.Title>
                                        <Card.Text>
                                                              
                                        </Card.Text>
                                    </Card.Body>
                                    {votes ? <>{votes.map((v, index) => (
                                        <VoteListRow vote={v} key={v.id+index}/>
                                    ))}
                                    </> : <>no votes</>}
                                </Card>
                            </Col>
                            

                        </Row>
                    </Container>
                </div>}
            {!user && <div>
                <Container className={"container-fluid"}>
                    <Row className={"d-flex"}>
                        <Col xs className={"p-2 mt-5"}>
                            <Link to={'/' +
                                'login'}><h4>Please log in</h4></Link>
                            <p>You can give feedback on other people's proposals and improve your proposal based on
                                the feedback you receive. In this way, we will come to a set of quality session
                                proposals in a transparent, community driven way.</p>
                            <br/>
                        </Col>
                        <Col className="p-2">
                            <Image
                                src="https://img.freepik.com/free-vector/happy-freelancer-with-computer-home-young-man-sitting-armchair-using-laptop-chatting-online-smiling-vector-illustration-distance-work-online-learning-freelance_74855-8401.jpg?w=900&t=st=1667037491~exp=1667038091~hmac=7c71ea8afc8f3cc8065c5ccc05d105e3c8a7b76f0133016cb210a7882dc19611"
                                className="img-fluid" style={{minWidth: '464px'}} alt="..."/>
                        </Col>
                    </Row>
                </Container>

            </div>}
        </>)
}

function SessionListRow(props){
    const {session}=props

    return(
        <div style={{marginLeft:"1em",marginRight:"1em",borderTop:"1px solid #0000002d"}}>
            <Link to={'/session/'+session.id}>
                <div style={{marginBottom:"0.2em"}}>{session.title}</div>
            </Link>
            <div style={{color:"#0000008e"}}>{getSessionDate(session)}</div>
        </div>
    )
}


function VoteListRow(props) {
    const { vote } = props;
    const [sessionTitle, setSessionTitle] = useState(null);

    useEffect(() => {
        const fetchSessionTitle = async () => {
            try {
                const session = await dbApi.getSession(vote.sessionID);
                setSessionTitle(session?.title);
            } catch (error) {
            }
        };

        fetchSessionTitle();
    }, [vote.sessionID]);

    return (
        <div style={{ marginLeft: "1em", marginRight: "1em", borderTop: "1px solid #0000002d" }}>
            <Link to={'/session/' + vote.sessionID}>
                <div style={{ marginBottom: "0.2em" }}>{sessionTitle}</div>
            </Link>
        </div>
    );
}

function getProfileProgress(presenter){
    let progress = 0;
    if(presenter){
        if(presenter.profilePicture)progress+=25
        if(presenter.bio)progress+=25
        if(presenter.twitter)progress+=25
        if(presenter.website)progress+=25
    }
    return progress
}

function getSessionDate(session){
    if(!session)return 'review undefined'
    if(session.modified&&session.modified.seconds!==session.created.seconds)return "Modified: "+(dbApi.convertTimestampToDate(session.modified).getDate()<10?'0'+dbApi.convertTimestampToDate(session.modified).getDate():dbApi.convertTimestampToDate(session.modified).getDate())+"/"+(dbApi.convertTimestampToDate(session.modified).getMonth()<9?"0"+(dbApi.convertTimestampToDate(session.modified).getMonth()+1):dbApi.convertTimestampToDate(session.modified).getMonth()+1)+"/"+dbApi.convertTimestampToDate(session.modified).getFullYear()+" "+(dbApi.convertTimestampToDate(session.modified).getHours()<10?"0"+dbApi.convertTimestampToDate(session.modified).getHours():dbApi.convertTimestampToDate(session.modified).getHours())+":"+(dbApi.convertTimestampToDate(session.modified).getMinutes()<10?"0"+dbApi.convertTimestampToDate(session.modified).getMinutes():dbApi.convertTimestampToDate(session.modified).getMinutes())
    if(session.created)return "created: "+(dbApi.convertTimestampToDate(session.created).getDate()<10?'0'+dbApi.convertTimestampToDate(session.created).getDate():dbApi.convertTimestampToDate(session.created).getDate())+"/"+(dbApi.convertTimestampToDate(session.created).getMonth()<9?"0"+(dbApi.convertTimestampToDate(session.created).getMonth()+1):dbApi.convertTimestampToDate(session.created).getMonth()+1)+"/"+dbApi.convertTimestampToDate(session.created).getFullYear()+" "+(dbApi.convertTimestampToDate(session.created).getHours()<10?"0"+dbApi.convertTimestampToDate(session.created).getHours():dbApi.convertTimestampToDate(session.created).getHours())+":"+(dbApi.convertTimestampToDate(session.created).getMinutes()<10?"0"+dbApi.convertTimestampToDate(session.created).getMinutes():dbApi.convertTimestampToDate(session.created).getMinutes())
    return 'date undefined'
}