import { Container, Card, Form, Button, Row, Col, Popover, OverlayTrigger} from "react-bootstrap";
import { dbApi } from "../services/firebase";
import { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

export function ConfigurationPage() {
 const stateVotingPromise = useMemo(() => dbApi.getVotingActiveState(), []);
 const [stateVoting, setStateVoting] = useState(false);

 const stateSessionSubmissionPromise = useMemo(() => dbApi.getSessionSubmissionState(), []);
 const [stateSessionSubmission, setStateSessionSubmission] = useState(false);

 const stateReviewsAndCommentsPromise = useMemo(() => dbApi.getReviewsAndCommentsState(), []);
 const [stateReviewsAndComments, setStateReviewAndComments] = useState(false);

 const stateXpFactorPromise = useMemo(() => dbApi.getXpFactorActiveState(), []);
 const [stateXpFactor, setStateXpFactor] = useState(false);

 const stateSessionEditPromise = useMemo(() => dbApi.getSessionEditState(), []);
 const [stateSessionEdit, setStateSessionEdit] = useState(false);

 const stateVoteForOwnSessionPromise = useMemo(() => dbApi.getVoteForOwnSessionState(), []);
 const [stateVoteForOwnSession, setStateVoteForOwnSession] = useState(false);

 const amountOfVotesPromise = useMemo(() => dbApi.amountOfVotesCast(), []);
 const [amountOfVotes, setAmountOfVotes] = useState(0);

 const dashboardPromise = useMemo(() => dbApi.getDashboard(), []);
 const [dashboard, setDashboard] = useState(undefined);
 const [title, setTitle] = useState(dashboard?.title || "");
 const [text, setText] = useState(dashboard?.text || "");
 const [file, setFile] = useState(null);
 const [experienceState, setExperienceState] = useState("");

 const stateReviewVisiblePromise = useMemo(() => dbApi.getReviewsVisibleState(), []);
 const [stateReviewVisible, setStateReviewVisible] = useState(true);

  const deleteAccountRequestsPromise = useMemo(() => dbApi.getPresentersWithDeletionRequests(), []);
  const [promisedRequests, setPromisedRequests] = useState(undefined);
  const requests = promisedRequests;

  const navigate =useNavigate()

 async function uploadDashboardPicture(file) {
  try {
   const allowedFormats = ["image/jpeg", "image/png", "image/svg+xml"];
   if (!allowedFormats.includes(file.type)) {
    throw new Error("Please choose a JPEG, PNG, or SVG file.");
   }
   const uploadedImageUrl = await dbApi.uploadDashboardImage(file);
   await dbApi.setDashboardImageLink(uploadedImageUrl);
   return uploadedImageUrl;
  } catch (error) {
   alert(error.message);
   console.error("Error uploading dashboard picture:", error.message);
   throw error;
  }
 }

  useEffect(() => {
    deleteAccountRequestsPromise.then(promisedRequests => setPromisedRequests(promisedRequests));
  }, [deleteAccountRequestsPromise]);
  

 useEffect(() => {
  dbApi.getExperienceState().then(promisedExperienceState => setExperienceState(promisedExperienceState));
 }, []);

 useEffect(() => {
  if (!dashboard) return;
  setTitle(dashboard.title);
  setText(dashboard.text);
 }, [dashboard]);

 useEffect(() => {
  dashboardPromise?.then(promisedDashboard => {
   if (!promisedDashboard) return;
   if (promisedDashboard.length === 0) return;
   setDashboard(promisedDashboard);
  });
 }, [dashboardPromise]);

 useEffect(() => {
  amountOfVotesPromise.then(promisedAmountOfVotes => {
   setAmountOfVotes(promisedAmountOfVotes);
   console.log("amount of votes:  " + promisedAmountOfVotes);
  });
 }, [amountOfVotesPromise]);

 useEffect(() => {
  stateSessionEditPromise.then(promisedState => {
   setStateSessionEdit(promisedState);
   console.log("editing:" + promisedState);
  });
 }, [stateSessionEditPromise]);

 useEffect(() => {
  stateVotingPromise.then(promisedState => {
   setStateVoting(promisedState);
   console.log("voting:" + promisedState);
  });
 }, [stateVotingPromise]);

 useEffect(() => {
  stateSessionSubmissionPromise.then(promisedState => {
   setStateSessionSubmission(promisedState);
   console.log("sessions:" + promisedState);
  });
 }, [stateSessionSubmissionPromise]);

 useEffect(() => {
  stateXpFactorPromise.then(promisedState => {
   setStateXpFactor(promisedState);
   console.log("xp factor:  " + promisedState);
  });
 }, [stateXpFactorPromise]);

 useEffect(() => {
  stateReviewsAndCommentsPromise.then(promisedState => {
   setStateReviewAndComments(promisedState);
   console.log("Reviews and comments:  " + promisedState);
  });
 }, [stateReviewsAndCommentsPromise]);

 useEffect(() => {
  stateVotingPromise.then(promisedState => {
   setStateVoting(promisedState);
   console.log("voting:" + promisedState);
  });
 }, [stateVotingPromise]);

 useEffect(() => {
  stateSessionSubmissionPromise.then(promisedState => {
   setStateSessionSubmission(promisedState);
   console.log("sessions:" + promisedState);
  });
 }, [stateSessionSubmissionPromise]);

 useEffect(() => {
  stateVoteForOwnSessionPromise.then(promisedState => {
   setStateVoteForOwnSession(promisedState);
   console.log("voting for own session:" + promisedState);
  });
 }, [stateVoteForOwnSessionPromise]);

 useEffect(() => {
  stateReviewVisiblePromise.then(promisedState => setStateReviewVisible(promisedState));
 }, [stateReviewVisiblePromise]);

 

  useEffect(() => {
    dbApi.getPresentersWithDeletionRequests().then(setPromisedRequests);
  }, []);

  const handleDeleteAccount = async (id) => {
    await dbApi.deletePresenter(id);
    setPromisedRequests((prevRequests) => prevRequests.filter((request) => request.id !== id));
  };

  const handleCancelRequest = async (id) => {
    await dbApi.cancelDeletionRequest(id);
    setPromisedRequests((prevRequests) => prevRequests.filter((request) => request.id !== id));
  };

  const accountDeletionRequestList = () => {
    if (!requests) {
      return <div>Loading...</div>;
    }
    if (requests.length === 0) {
      return <div>No account deletion requests</div>;
    }
    return (
      <div>
        {requests.map((request) => (
          <div style={{ backgroundColor: "#ffded4", borderRadius: "1em", margin: "1em", padding: "1em" }} key={request.id}>
            <Link style={{ margin: "1em" }} to={`/profile/${request.id}`}>{request.firstName} {request.lastName}</Link> <span> {request.email}</span>
            <OverlayTrigger
              rootClose={true}
              trigger="click"
              placement="right"
              overlay={
                <Popover id={`popover-${request.id}`}>
                  <Popover.Header as="h3" style={{ textDecoration: 'underline', color: 'red' }}>Send account deletion request?</Popover.Header>
                  <Popover.Body>
                    <strong>Are you sure you want to delete this account?</strong> This action cannot be undone. <br />
                    <br />
                    <Button variant="outline-danger" style={{ fontSize: "0.8em" }} onClick={() => handleDeleteAccount(request.id)}>
                      Delete account
                    </Button>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button className="btn btn-sm" style={{ fontSize: "0.8em", float: "right", marginRight: "1em" }} variant="outline-danger">Delete account</Button>
            </OverlayTrigger>
            <Button
              variant="outline-primary"
              className="btn btn-sm"
              style={{ fontSize: "0.8em", float: "right", marginRight: "1em" }}
              onClick={() => handleCancelRequest(request.id)}
            >
              Cancel
            </Button>
          </div>
        ))}
      </div>
    );
  };
  
 return (
  <Container className={"container-fluid"}>
   <Container className={"container-fluid"} style={{ marginTop: "2.5rem" }}>
    <h1>
     <b>Configuration</b>
    </h1>
   </Container>
     <Container style={{ marginTop: "2.5rem", border: 5, borderColor: "black" }}>
     
       <Card border="dark" style={{ padding: "1rem 2rem 2rem 2rem", margin: "2rem 0rem 1rem 0rem" }}>
        <Card.Title style={{ paddingBottom: "2rem"}}>Account Deletion Requests</Card.Title>

        {accountDeletionRequestList()}
        </Card>
    <Card border="dark" style={{ padding: "1rem 2rem 2rem 2rem" }}>
     <Card.Title style={{ paddingBottom: "2rem" }}>Session Timeline</Card.Title>
     <div
      style={{
       fontSize: "1.2em",
       paddingBottom: "1rem",
      }}>
      <Form.Check type="switch" id="custom-switch" label="Voting active" checked={stateVoting} onChange={() => dbApi.setVotingActiveState(!stateVoting).then(() => setStateVoting(!stateVoting))} />
      <Form.Check type="switch" id="custom-switch" label="Session submission active" checked={stateSessionSubmission} onChange={() => dbApi.setSessionSubmissionState(!stateSessionSubmission).then(() => setStateSessionSubmission(!stateSessionSubmission))} />
      <Form.Check type="switch" id="custom-switch" label="Session edit active" checked={stateSessionEdit} onChange={() => dbApi.setSessionEditState(!stateSessionEdit).then(() => setStateSessionEdit(!stateSessionEdit))} />
     </div>
    </Card>
   </Container>

   <Container className={"container-fluid"}>
    <Card border="dark" style={{ padding: "1rem 2rem 2rem 2rem", margin: "2rem 0rem 0rem 0rem" }}>
     <Card.Title style={{ paddingBottom: "2rem" }}>Dashboard</Card.Title>
     <div style={{ fontSize: "1.2em", paddingBottom: "1rem" }}>
      <Form.Control type="text" style={{ marginBottom: "1rem", maxWidth: "75%" }} placeholder={"title"} value={title} onChange={e => setTitle(e.target.value)} />
      <Form.Control as="textarea" rows={3} style={{ marginBottom: "1rem", maxWidth: "75%" }} placeholder="Text" value={text} onChange={e => setText(e.target.value)} />
      <Button variant="outline-danger" style={{ fontSize: "0.8em", margin: " 0em 0em 1em 0em" }} onClick={() => dbApi.editDashboard(title, text)}>
       Edit
      </Button>
      <div style={{ fontSize: "1em" }}>Upload picture:</div>
      <Row>
       <Col xs={6}>
        <Form.Control type="file" style={{ marginBottom: "1rem" }} onChange={e => setFile(e.target.files[0])} />
       </Col>
       <Col xs={6}>
        <Button variant="outline-danger" style={{ fontSize: "0.8em" }} onClick={() => uploadDashboardPicture(file)}>
         Upload
        </Button>
       </Col>
      </Row>
     </div>
    </Card>
   </Container>

   <Container className={"container-fluid"}>
    <Card border="dark" style={{ padding: "1rem 2rem 2rem 2rem", margin: "2rem 0rem 0rem 0rem" }}>
     <Card.Title style={{ paddingBottom: "2rem" }}>Initial Event Setup</Card.Title>
     <div style={{ fontSize: "1.2em", paddingBottom: "1rem" }}>
      <Form.Check type="switch" id="custom-switch" label="Make Reviews and comments visible" checked={stateReviewVisible} onChange={() => dbApi.setReviewsVisibleState(!stateReviewVisible).then(() => setStateReviewVisible(!stateReviewVisible))} />
      <Form.Check type="switch" id="custom-switch" label="Make Reviews and comments active" checked={stateReviewsAndComments} onChange={() => dbApi.setReviewsAndCommentsActive(!stateReviewsAndComments).then(() => setStateReviewAndComments(!stateReviewsAndComments))} />
      <Form.Check type="switch" id="custom-switch" label="XP factor active" checked={stateXpFactor} onChange={() => dbApi.setXpFactorActive(!stateXpFactor).then(() => setStateXpFactor(!stateXpFactor))} />
      <Form.Check type="switch" id="custom-switch" label="Experience active" checked={experienceState} onChange={() => dbApi.setExperienceState(!experienceState).then(() => setExperienceState(!experienceState))} />
      <Form.Check type="switch" id="custom-switch" label="Can vote for own session" checked={stateVoteForOwnSession} onChange={() => dbApi.setVoteForOwnSessionState(!stateVoteForOwnSession).then(() => setStateVoteForOwnSession(!stateVoteForOwnSession))} />
      <Card style={{ padding: "1rem 0rem 0rem 1rem", maxWidth: "20%", minWidth: "250px" }}>
       <div style={{ fontSize: "1em" }}>amount of votes to cast:</div>
       <Row>
        <Col xs={5}>
         <Form.Control type="number" style={{ marginBottom: "1rem", maxWidth: "5em" }} placeholder="0" value={amountOfVotes} onChange={e => setAmountOfVotes(e.target.value)} />
        </Col>
        <Col xs={6}>
         <Button variant="outline-primary" className="btn btn-sm" style={{ fontSize: "0.8em" }} onClick={() => dbApi.setAmountOfVotesCast(amountOfVotes)}>
          Set
         </Button>
        </Col>
       </Row>
      </Card>
     </div>
    </Card>
   </Container>
  </Container>
 );
}
