import {dbApi} from "../services/firebase";
import {useState} from "react";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {Button, Table, Container} from "react-bootstrap";
import {useAuthContext} from "../contexts/authContext";
import { UserOnly } from "../components/UserOnly";


export default function PresentersPage() {
    const {user, currentPresenter} = useAuthContext();
    const [presentersWithAddedData, setPresentersWithAddedData] = useState(undefined); // Presenters with votes # and reviews #
    const [sortCondition, setSortCondition] = useState(() => sortByName);

    // Load presenters, votes and reviews and modify presenters to include their vote & review count
    useEffect(() => {
        (async () => {
            // Get all presenters, votes and reviews
            const [presenters, votes, reviews] = await Promise.all([
                dbApi.getAllPresenters(),
                dbApi.getVotes(),
                dbApi.getAllReviews()]);
            if (!presenters || presenters.length === 0) {
                setPresentersWithAddedData([]);
                return;
            }
            const modifiedPresenters = await Promise.all(presenters.map(async (presenter) => {
                // Add vote & review count to each presenter
                const totalVotes = votes.filter((vote) => vote.votedBy === presenter.id).length;
                const totalReviews = reviews.filter((review) => review.reviewedBy === presenter.id).length;
                presenter.totalVotes = totalVotes !== undefined ? totalVotes : 0;
                presenter.totalReviews = totalReviews !== undefined ? totalReviews : 0;
                // Fix for presenters who have uploaded a profile picture before the pfp bugfix
                if (!presenter.profilePicture) {
                    // Add profile picture to presenter
                    presenter.profilePicture = await dbApi.getPresenterProfilePicture(presenter.id);
                }
                return presenter;
            }));
            setPresentersWithAddedData(modifiedPresenters);
        })();
    }, []);

    return (
        <Container style={{maxWidth:"75%"}}>
        <UserOnly >
            <h1 className={"sessionListTitle"}>Presenters</h1>
            <div className={"sessionListSubTitle"}>List contains <b>{presentersWithAddedData ? presentersWithAddedData.length : "..."}</b> presenters</div>
            {
                presentersWithAddedData &&
                <div className={"table-responsive-sm"}>
                    <Table bordered striped className={"sessionTable"}>
                        <thead>
                        <tr>
                            <th style={{width: "2%", cursor: 'default'}}/>
                            <th onClick={() => setSortCondition(sortCondition !== sortByName ? () => sortByName : () => sortByNameReverse)}>Name{sortCondition === sortByName ? <> &#8595;</> : ''}{sortCondition === sortByNameReverse ? <> &#8593;</> : ''}</th>
                            {currentPresenter.role === "admin" && <>
                                <th
                                    onClick={() => setSortCondition(sortCondition !== sortByReviews ? () => sortByReviews : () => sortByReviewsReverse)}>#Reviews{sortCondition === sortByReviews ? <> &#8595;</> : ''}{sortCondition === sortByReviewsReverse ? <> &#8593;</> : ''}</th>
                                <th onClick={() => setSortCondition(sortCondition !== sortByVotes ? () => sortByVotes : () => sortByVotesReverse)}>#Votes{sortCondition === sortByVotes ? <> &#8595;</> : ''}{sortCondition === sortByVotesReverse ? <> &#8593;</> : ''}</th>
                            </>}
                        </tr>
                        </thead>
                        <tbody>
                        {presentersWithAddedData.sort(sortCondition).map((p, index) => (
                            <tr key={index}>
                                <th>{index + 1}</th>
                                <th style={{padding: "0.2em"}}><Link to={"/profile/" + p.id}
                                                                     className={"sessionListLink"}>{p.profilePicture ?
                                    <img style={{marginRight: "0.5em"}} src={p.profilePicture} width={"33em"}
                                         height={"33em"} alt={"profilepic"}/> :
                                    <img style={{marginRight: "0.5em"}} src={"/person-bounding-box.svg"} width={"33em"}
                                         height={"33em"} alt={"no profilepic"}/>}{p.firstName} {p.lastName}</Link>
                                </th>
                                {user && currentPresenter.role === 'admin' &&
                                    <>
                                        <th>{p.totalReviews}</th>
                                        <th>{p.totalVotes}</th>
                                    </>
                                }
                            </tr>
                        ))}

                        </tbody>
                    </Table>
                </div>
            }
            {
                presentersWithAddedData && presentersWithAddedData.length === 0 &&
                <>No presenters yet</>
            }

             <Button
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      borderColor: "black",
                      color: "black",
                }}
                onClick={() => dowloadEmailList()}
                  >Download EmailList</Button>
            </UserOnly>
        </Container>
    );
}

function sortByName(a, b) {
    if (a.lastName === undefined || a.lastName === null) {
        return 1
    }
    if (b.lastName === undefined || b.lastName === null) {
        return -1
    }
    if (a.lastName.toUpperCase() < b.lastName.toUpperCase()) {
        return -1;
    }
    if (a.lastName.toUpperCase() > b.lastName.toUpperCase()) {
        return 1;
    }
    return 0;
}

function sortByNameReverse(a, b) {
    if (a.lastName === undefined || a.lastName === null) {
        return -1
    }
    if (b.lastName === undefined || b.lastName === null) {
        return 1
    }
    if (a.lastName.toUpperCase() > b.lastName.toUpperCase()) {
        return -1;
    }
    if (a.lastName.toUpperCase() < b.lastName.toUpperCase()) {
        return 1;
    }
    return 0;
}

function sortByVotes(a, b) {
    if (!a) return 1
    if (!b) return -1
    if (a.totalVotes > b.totalVotes) return 1
    if (a.totalVotes < b.totalVotes) return -1
    return 0
}

function sortByVotesReverse(a, b) {
    if (!a) return -1
    if (!b) return 1
    if (a.totalVotes > b.totalVotes) return -1
    if (a.totalVotes < b.totalVotes) return 1
    return 0
}

function sortByReviews(a, b) {
    if (!a) return 1
    if (!b) return -1
    if (a.totalReviews > b.totalReviews) return 1
    if (a.totalReviews < b.totalReviews) return -1
    return 0
}

function sortByReviewsReverse(a, b) {
    if (!a) return -1
    if (!b) return 1
    if (a.totalReviews > b.totalReviews) return -1
    if (a.totalReviews < b.totalReviews) return 1
    return 0
}

const dowloadEmailList = () => {
    dbApi.downloadPresenterEmails();
};

