import { Link, useParams, useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { dbApi } from "../services/firebase";
import { useMemo, useEffect, useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { useAuthContext } from "../contexts/authContext";
import { FormSubmitButton } from "./FormSubmitButton";
import { ReviewList } from "./ReviewList";
import { OverlayTrigger, Popover } from "react-bootstrap";

export function Profile(email) {
 const { currentPresenter, user } = useAuthContext();
 const { presenterId } = useParams();
 const presenterPromise = useMemo(() => dbApi.getPresenter(presenterId), [presenterId]);
 const [presenter, setPresenter] = useState(undefined);
 const [presenterEdit, setPresenterEdit] = useState(presenter);
 const [mail, setMail] = useState();
 const [mailEdit, setMailEdit] = useState(mail);
 const [mailConfirm, setMailConfirm] = useState(undefined);
 const [mailChanged, setMailChanged] = useState(false);
 const [editMode, setEditMode] = useState(false);
 const [showImageInput, setShowImageInput] = useState(false);
 const [file, setFile] = useState(undefined);
 const [reviews, setReviews] = useState([]);
 const [sessions, setSessions] = useState([]);
 const [errorMail, setErrorMail] = useState(false);
 const [userData, setUserData] = useState(undefined);
 const [links, setLinks] = useState([...(presenter?.links || [{ type: "", url: "" }])]);
 const navigate=useNavigate()

 function sanitizeUrl(url) {
  return url.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "").replace(/^[\u0000-\u001F ]*j[\r\n\t]*a[\r\n\t]*v[\r\n\t]*a[\r\n\t]*s[\r\n\t]*c[\r\n\t]*r[\r\n\t]*i[\r\n\t]*p[\r\n\t]*t[\r\n\t]*:/i, "");
 }
 // eslint-disable-next-line
 const reviewsPromise = useMemo(() => dbApi.getReviewsOfPresenter(presenter?.id), [presenter]);
 const sessionsPromise = useMemo(() => dbApi.getSessionsOfPresenter(presenter?.id), [presenter]);
 const userDataPromise = useMemo(() => dbApi.getUserData(presenter?.user), [presenter]);
 const [passwordConfirmation, setPasswordConfirmation] = useState(undefined);
 const [displayMail, setDisplayMail] = useState(null);

 useEffect(() => {
  if (presenter && presenter.displayMail !== undefined) {
   setDisplayMail(presenter.displayMail);
  }
  if (presenter && presenter.links !== undefined) {
   setLinks(presenter.links);
  }
 }, [presenter]);

 const toggleDisplayMail = () => {
  setPresenterEdit(prevState => ({
   ...prevState,
   displayMail: !prevState.displayMail,
  }));
 };

 const addLink = () => {
  setLinks([...links, { type: "", url: "" }]);
  setPresenterEdit({
   ...presenterEdit,
   links: [...links, { type: "", url: "" }],
  });
 };

 const removeLink = indexToRemove => {
  const newLinks = links.filter((_, index) => index !== indexToRemove);
  setLinks(newLinks);
  setPresenterEdit({ ...presenterEdit, links: newLinks });
 };

 useEffect(() => {
  if (user) {
   setMail(user.email);
   setMailEdit(user.email);
  }
 }, [user]);

 useEffect(() => {
  userDataPromise
   .then(promisedUserData => {
    if (promisedUserData !== undefined) {
     setUserData(promisedUserData);
    } else {
    }
   })
   .catch(error => {});
 }, [userDataPromise]);

 useEffect(() => {
  (async () => {
   const presenter = await presenterPromise;
   setPresenter(presenter);
   setPresenterEdit(presenter);
  })().catch(error => {});
 }, [presenterPromise]);

 useEffect(() => {
  reviewsPromise
   ?.then(promisedReviews => {
    if (!promisedReviews) return;
    if (promisedReviews.length === 0) return;
    setReviews(promisedReviews);
   })
   .catch(error => {});
 }, [reviewsPromise]);

 useEffect(() => {
  sessionsPromise
   ?.then(promisedSessions => {
    if (!promisedSessions) return;
    if (promisedSessions.length === 0) return;
    setSessions(promisedSessions);
   })
   .catch(error => {});
 }, [sessionsPromise]);
  
  
 const popover = (
  <Popover id="popover-basic">
   <Popover.Header as="h3" style={{textDecoration: 'underline', color: 'red'}}>Send account deletion request?</Popover.Header>
   <Popover.Body>
       Upon sending this request your account will be reviewed by staff and all your <strong> data will be erased</strong>.
       There is <strong>no way</strong> to recover your <strong>data</strong> after this action.
       <br />
    <Button variant="outline-danger" onClick={()=>navigate("/deleteAccount/")}  className={"btn btn-sm"} >
     Request to delete account
    </Button>
   </Popover.Body>
  </Popover>
 );

 return (
  presenter &&
  user && (
   <>
    <Card className={"m-lg-5 mx-md-auto mt-2"}>
     <Card.Header className={"text-center card-header-standard"}>
      <h1 className={"d-inline"} style={{ color: "white" }}>
       {presenter.firstName + " " + presenter.lastName}
      </h1>
      {currentPresenter && (currentPresenter.id === presenterId || currentPresenter.role === "admin") && (
       <>
        {editMode ? (
         <Button
          onClick={() => setEditMode(false)}
          style={{
           right: "1vw",
           position: "absolute",
           backgroundColor: "black",
           borderColor: "black",
           color: "white",
          }}>
          <BsFillPencilFill style={{ marginBottom: "4px" }} /> Cancel
         </Button>
        ) : (
         <Button
          className={"my-lg-1 my-sm-auto"}
          onClick={() => setEditMode(true)}
          style={{
           right: "1vw",
           position: "absolute",
           backgroundColor: "black",
           borderColor: "black",
           color: "white",
          }}>
          <BsFillPencilFill style={{ marginBottom: "4px" }} /> edit
         </Button>
        )}
       </>
      )}
     </Card.Header>
     <Card.Body>
      {!editMode && (
       <Container>
        <Row>
         <Col xxl={4} xl={12} lg={12} md={12}>
          {presenter.profilePicture && (
           <div style={{ marginBottom: "2em" }}>
            <img className={"rounded mx-auto d-block"} width={"100%"} src={presenter.profilePicture} alt={"presenter"} />
           </div>
          )}
         </Col>
         <Col lg={12} xxl={8}>
          {presenter.bio && (
           <>
            <h2>Bio</h2>
            <div>{presenter.bio}</div>
           </>
          )}
          {presenter.twitter && (
           <div style={{ marginTop: "1em" }}>
            <img style={{ marginRight: "0.2em", maxWidth: "1.5em" }} src="/x.svg" alt="x (twitter)logo" />
            <a rel="noreferrer" target={"_blank"} style={{ textDecoration: "none" }} href={"https://twitter.com/" + presenter.twitter.replaceAll("@", "")}>
             <div className={"d-inline"}>@</div>
             <div className={"d-inline"} style={{ textDecoration: "underline" }}>
              {presenter.twitter.replaceAll("@", "")}
             </div>
            </a>
           </div>
          )}
          {presenter.linkedin && (
           <div style={{ marginTop: "1em" }}>
            <img style={{ marginRight: "0.2em" }} src="/linkedin.svg" alt="linkedin logo" />
            <a rel="noreferrer" target={"_blank"} style={{ textDecoration: "none" }} href={"https://linkedin.com/in/" + presenter.linkedin}>
             <div className={"d-inline"}> </div>
             <div className={"d-inline"} style={{ textDecoration: "underline" }}>
              {presenter.linkedin}
             </div>
            </a>
           </div>
          )}
          {presenter.company && (
           <div style={{ marginTop: "1em" }}>
            <img style={{ marginRight: "0.2em", maxWidth: "1.7em" }} src="/building.svg" alt="company logo" />
            {presenter.company}
           </div>
          )}
          {presenter.instagram && (
           <div style={{ marginTop: "1em" }}>
            <img style={{ marginRight: "0.2em" }} src="/instagram.svg" alt="instagram logo" />
            <a rel="noreferrer" target={"_blank"} style={{ textDecoration: "none" }} href={"https://instagram.com/" + presenter.instagram.replaceAll("@", "")}>
             <div className={"d-inline"}>@</div>
             <div className={"d-inline"} style={{ textDecoration: "underline" }}>
              {presenter.instagram.replaceAll("@", "")}
             </div>
            </a>
           </div>
          )}
          {presenter.website && (
           <div style={{ marginTop: "1em" }}>
            <a target={"_blank"} href={presenter.website.startsWith("http") ? presenter.website : "http://" + presenter.website} rel="noreferrer">
             <img style={{ marginRight: "0.4em" }} src="/globe.svg" alt="website icon" />
             {presenter.website}
            </a>{" "}
           </div>
          )}
          {presenter.displayMail && (
           <div style={{ marginTop: "1em" }}>
            <img style={{ marginRight: "0.4em" }} src="/envelope-at.svg" alt="email icon" />
            {presenter.email}
           </div>
          )}

          {presenter.links &&
           presenter.links.length > 0 &&
           presenter.links.map((link, index) => (
            <div key={index}>
             <div style={{ marginTop: "1em" }}>
              {" "}
              {link.type}{" "}
              <a target={"_blank"} href={link.url} rel="noreferrer">
               {link.url}
              </a>
             </div>
            </div>
           ))}
         </Col>
        </Row>
       </Container>
      )}
      {editMode && (
       <Container>
        <Row>
         <Col xxl={4} xl={12} lg={12} md={12}>
          {presenter.profilePicture && presenter.profilePicture !== "" && (
           <div style={{ marginBottom: "2em" }}>
            <div style={{ marginBottom: "0.5em" }} className={"text-center"}>
             {!showImageInput ? (
              <button onClick={() => setShowImageInput(true)} style={{ width: "25em" }} className={"btn button-standard"}>
               Change profile picture
              </button>
             ) : (
              <button style={{ width: "25em" }} className={"btn button-standard"} disabled>
               Change profile picture
              </button>
             )}
            </div>
            {showImageInput && (
             <div
              style={{
               marginLeft: "3.5em",
               marginBottom: "0.5em",
              }}>
              <input style={{ display: "block" }} type="file" accept="image/*" onChange={event => setFile(event.target.files[0])} />
             </div>
            )}
            <img className={"rounded mx-auto d-block"} width={"100%"} src={presenter.profilePicture} alt={"presenter"} />
           </div>
          )}
          {(!presenter.profilePicture || presenter.profilePicture === "") && (
           <>
            <div style={{ marginBottom: "0.5em" }} className={"text-center"}>
             {!showImageInput ? (
              <button onClick={() => setShowImageInput(true)} style={{ width: "25em" }} className={"btn button-standard"}>
               Upload a profile picture
              </button>
             ) : (
              <button style={{ width: "25em" }} className={"btn button-standard"} disabled>
               Upload a profile picture
              </button>
             )}
            </div>
            {showImageInput && (
             <div
              style={{
               marginLeft: "3.5em",
               marginBottom: "0.5em",
              }}>
              <input style={{ display: "block" }} type="file" accept="image/*" onChange={event => setFile(event.target.files[0])} />
             </div>
            )}
           </>
          )}
         </Col>
         <Col lg={12} xxl={8}>
          <Form
           onSubmit={e => {
            e.preventDefault();
            let noError = true;
            if (mailChanged && mailEdit !== mailConfirm) {
             setErrorMail(true);
             noError = false;
            } else {
             setErrorMail(false);
            }
            if (!errorMail && noError && ((mailChanged && passwordConfirmation) || !mailChanged)) {
             handleSubmit(presenterEdit, presenterId, mailEdit, currentPresenter, passwordConfirmation, user, mailChanged, setPresenter, setEditMode, file);
            }
           }}>
           <Form.Group>
            <Form.Label style={{ marginBottom: "0" }}>Bio</Form.Label>
            <Form.Control
             className={"textAreaSessionEdit"}
             style={{ height: "14em" }}
             as="textarea"
             defaultValue={presenter.bio}
             onChange={e =>
              setPresenterEdit({
               ...presenterEdit,
               bio: e.target.value,
              })
             }
            />
           </Form.Group>
           <Form.Group style={{ marginTop: "1em" }}>
            <Form.Label style={{ marginBottom: "0" }}>x (Twitter) username</Form.Label>
            <img style={{ marginRight: "0.2em", maxWidth: "1.5em" }} src="/x.svg" alt="x (twitter) logo" />
            <Form.Control
             className={"session-input-field"}
             style={{ height: "2em" }}
             type="text"
             defaultValue={presenter.twitter}
             onChange={e =>
              setPresenterEdit({
               ...presenterEdit,
               twitter: e.target.value,
              })
             }
            />
           </Form.Group>
           <Form.Group style={{ marginTop: "1em" }}>
            <Form.Label style={{ marginBottom: "0" }}>LinkedIn username (the part after linkedin.com/in/)</Form.Label>
            <img style={{ marginLeft: "0.2em", marginTop: "-0.5em" }} src="/linkedin.svg" alt="linkedin logo" />
            <Form.Control
             className={"session-input-field"}
             style={{ height: "2em" }}
             type="text"
             defaultValue={presenter.linkedin}
             onChange={e =>
              setPresenterEdit({
               ...presenterEdit,
               linkedin: e.target.value,
              })
             }
            />
           </Form.Group>{" "}
           <Form.Group style={{ marginTop: "1em" }}>
            <Form.Label style={{ marginBottom: "0" }}>
             Company (optional)
             <img style={{ marginLeft: "0.2em", maxWidth: "1.7em" }} src="/building.svg" alt="company logo" />
            </Form.Label>

            <Form.Control
             className={"session-input-field"}
             style={{ height: "2em" }}
             type="text"
             defaultValue={presenter.company}
             onChange={e =>
              setPresenterEdit({
               ...presenterEdit,
               company: e.target.value,
              })
             }
            />
           </Form.Group>
           <Form.Group style={{ marginTop: "1em" }}>
            <Form.Label style={{ marginBottom: "0" }}>Website</Form.Label>
            <img style={{ marginLeft: "0.2em", marginTop: "-0.5em" }} src="/globe.svg" alt="website icon" />
            <Form.Control
             className={"session-input-field"}
             style={{ height: "2em" }}
             type="text"
             defaultValue={presenter.website}
             onChange={e =>
              setPresenterEdit({
               ...presenterEdit,
               website: e.target.value,
              })
             }
            />
           </Form.Group>
           <Form.Group style={{ marginTop: "1em" }}>
            <Form.Label style={{ marginBottom: "0" }}>Instagram</Form.Label>
            <img style={{ marginLeft: "0.2em", marginTop: "-0.5em" }} src="/instagram.svg" alt="website icon" />
            <Form.Control
             className={"session-input-field"}
             style={{ height: "2em" }}
             type="text"
             defaultValue={presenter.instagram}
             onChange={e =>
              setPresenterEdit({
               ...presenterEdit,
               instagram: e.target.value,
              })
             }
            />
           </Form.Group>
           {links.map((link, index) => (
            <div key={index}>
             <Form.Group controlId={`linkType-${index}`}>
              <Form.Label>Link Type</Form.Label>
              <Form.Control
               type="text"
               value={link.type}
               onChange={e => {
                const newLinks = [...links];
                newLinks[index].type = e.target.value;
                setLinks(newLinks);
                setPresenterEdit({
                 ...presenterEdit,
                 links: newLinks,
                });
               }}
              />
             </Form.Group>
             <Form.Group controlId={`linkUrl-${index}`}>
              <Form.Label>URL</Form.Label>
              <Form.Control
               type="text"
               value={sanitizeUrl(link.url)}
               onChange={e => {
                const newLinks = [...links];
                newLinks[index].url = sanitizeUrl(e.target.value);
                setLinks(newLinks);
                setPresenterEdit({
                 ...presenterEdit,
                 links: newLinks,
                });
               }}
              />
             </Form.Group>
             <Button variant="danger" className="btn-sm" style={{ marginTop: "1em" }} onClick={() => removeLink(index)}>
              Remove
             </Button>
            </div>
           ))}
           <Button variant="danger" className="btn-sm" style={{ marginTop: "1em" }} onClick={addLink}>
            Add Link
           </Button>
           {currentPresenter.id === presenter.id && (
            <>
             {errorMail === true && (
              <div
               style={{
                color: "red",
                marginBottom: "-1em",
                marginTop: "1em",
                fontWeight: "bold",
               }}>
               The email addresses do not match!
              </div>
             )}
             <Form.Group style={{ marginTop: "1em" }}>
              <Form.Label style={{ marginBottom: "0" }}>Email</Form.Label>

              <img
               style={{
                marginLeft: "0.2em",
                marginTop: "-0.5em",
               }}
               src="/envelope-at.svg"
               alt="email icon"
              />

              <Form.Control
               className={"session-input-field"}
               style={{ height: "2em" }}
               type="email"
               defaultValue={mailEdit}
               onChange={e => {
                setMailEdit(e.target.value);
                if (e.target.value === mail) {
                 setMailChanged(false);
                } else {
                 setMailChanged(true);
                }
               }}
              />
             </Form.Group>

             <Form.Check
              type="switch"
              id="custom-switch"
              label="Show my e-mail on my profile"
              checked={displayMail}
              onChange={() => {
               setDisplayMail(!displayMail);
               toggleDisplayMail();
              }}
             />

             {mailChanged === true && (
              <>
               <Form.Group style={{ marginTop: "1em" }}>
                <Form.Label style={{ marginBottom: "0" }}>Confirm email</Form.Label>
                <img
                 style={{
                  marginLeft: "0.2em",
                  marginTop: "-0.5em",
                 }}
                 src="/envelope-at.svg"
                 alt="email icon"
                />
                <Form.Control
                 className={"session-input-field"}
                 style={{ height: "2em" }}
                 type="email"
                 onChange={e => {
                  setMailConfirm(e.target.value);
                 }}
                />
               </Form.Group>

               <Form.Group style={{ marginTop: "1em" }}>
                <Form.Label style={{ marginBottom: "0" }}>Confirm your password</Form.Label>
                <Form.Control className={"session-input-field"} style={{ height: "2em" }} type="password" onChange={e => setPasswordConfirmation(e.target.value)} />
               </Form.Group>
              </>
             )}
            </>
           )}
           {currentPresenter.id !== presenter.id && currentPresenter.role === "admin" && userData && (
            <div style={{ marginTop: "1em" }}>
             <a href={"mailto:" + userData.email} target={"_blank"} rel={"noreferrer"}>
              <img
               style={{
                marginRight: "0.4em",
                marginLeft: "0.05em",
               }}
               src="/envelope-at.svg"
               alt="email icon"
              />
              {userData.email}
             </a>
            </div>
                     )}
                   
                     
           {currentPresenter.role === "admin" ? (
            <Row>
             <Col xl={10}>
              <Form.Group style={{ marginTop: "1em" }}>
               <Form.Label style={{ marginBottom: "0" }}>Presenter role</Form.Label>
               <Form.Select
                className={"session-input-field"}
                style={{ height: "2.5em" }}
                defaultValue={presenter.role}
                onChange={e =>
                 setPresenterEdit({
                  ...presenterEdit,
                  role: e.target.value,
                 })
                }>
                <option value="presenter">presenter</option>
               <option value="admin">admin</option>
               <option value="reviewer">reviewer</option>
               </Form.Select>
              </Form.Group>
             </Col>
             <Col xl={2}>
              <div style={{ marginTop: "2.6em" }}>
               <FormSubmitButton className={"button-standard"}>Save changes</FormSubmitButton>
              </div>
             </Col>
            </Row>
           ) : (
            <div style={{ marginTop: "1.4em" }}>
             <FormSubmitButton className={"button-standard"}>Save changes</FormSubmitButton>
            </div>
             )}
               {currentPresenter.id === presenter.id && (
            <Container style={{ margin: "1em 0em 0em 0em"}}>
              <OverlayTrigger
                rootClose={true}
                trigger="click"
                placement="right"
                overlay={popover}
              >
                <Button variant="outline-danger">Delete account</Button>
              </OverlayTrigger>
           
          </Container>
             )}
          </Form>
         </Col>
        </Row>
       </Container>
      )}
     </Card.Body>
     {(sessions || reviews) && (sessions.length > 0 || reviews.length > 0) && (
      <Card.Body style={{ borderTop: "1px #0000002d solid" }}>
       <Container>
        <Row>
         {sessions && sessions.length > 0 && (
          <Col xl={6} md={12} style={{ marginTop: "1em", marginBottom: "1em" }}>
           <Card>
            <Card.Body>
             <Card.Title>
              {sessions.length > 1 ? "The sessions " : "The session "}
              {presenter.firstName + " " + presenter.lastName} is involved in
             </Card.Title>
             <Card.Text>
              <>
               {sessions.map((s, index) => (
                <span style={{ display: "block" }} key={index}>
                 <Link to={"/session/" + s.id}>
                  {" "}
                  <span>{s.title}</span>
                 </Link>
                </span>
               ))}
              </>
             </Card.Text>
            </Card.Body>
           </Card>
          </Col>
         )}
         {reviews && reviews.length > 0 && (
          <Col xl={6} md={12} style={{ marginTop: "1em", marginBottom: "1em" }}>
           <Card>
            <Card.Body>
             <Card.Title>{reviews.length === 1 ? presenter.firstName + " " + presenter.lastName + " wrote 1 review" : presenter.firstName + " " + presenter.lastName + " wrote " + reviews.length + " reviews"}</Card.Title>
            </Card.Body>
            {<ReviewList key={"reviewList"} reviews={reviews} />}
           </Card>
          </Col>
         )}
        </Row>
       </Container>
      </Card.Body>
     )}
    </Card>
   </>
  )
 );
}

async function uploadProfilePicture(file, presenterId) {
 try {
  const allowedFormats = ["image/jpeg", "image/png", "image/svg+xml"];
  if (!allowedFormats.includes(file.type)) {
   throw new Error("Please choose a JPEG, PNG, or SVG file.");
  }

  await dbApi.uploadProfilePicture(file, presenterId);
  const url = await dbApi.getPresenterProfilePicture(presenterId);
  await dbApi.setProfilePictureLink(presenterId, url);
  return url;
 } catch (error) {
  alert(error.message);
  console.error("Error uploading profile picture:", error.message);
  throw error;
 }
}

async function handleSubmit(presenter, presenterId, mailEdit, currentPresenter, passwordConfirmation, user, mailChanged, setPresenter, setEditMode, file) {
 if (presenter) {
  if (file) {
   presenter.profilePicture = await uploadProfilePicture(file, presenterId);
  }
  await dbApi.updatePresenter(presenter, presenterId);
  if (mailChanged) {
   if (presenter.id === currentPresenter.id) {
    await dbApi.updateMailAsUser(presenter.userId, mailEdit, passwordConfirmation, user);
   }
  }
  setPresenter(presenter);
  setEditMode(false);
 }
}
