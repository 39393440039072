import "../styles/sessionsList.css";
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Overlay,
	Badge,
} from "react-bootstrap";
import { useState, useEffect, useMemo, useRef } from "react";
import { dbApi } from "../services/firebase";
import { FormCard } from "./FormCard";
import { FormSubmitButton } from "./FormSubmitButton";
import { useAuthContext } from "../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { DropdownWithFilter } from "./DropdownWithFilter";
import { FaInfo } from "react-icons/fa";
import "./CreateSession.css";

function handleSubmit(title, subtitle, shortDescription, xpFactor, presenters, setSubmitted, reloadAuthState, navigate) {
    setSubmitted(false);
    
    //title check & subtitle check
    if (title.trim() === subtitle.trim()) {
    alert('Title and subtitle cannot be the same.');
    return;
}
    // This removes null values.
    // If second presenter is not set, it will be null, which we don't want to have in the array.
    presenters = presenters.filter(p => p);
    // Only pass id of presenters, we don't need more
    let presentersIds = presenters.map(p => p.id);
    if (xpFactor === 0|| xpFactor === null || xpFactor === undefined ){
        xpFactor = 1;
    }


    dbApi.createSession(title, subtitle, shortDescription, xpFactor, presentersIds)
        .then((sessionId) => {
            dbApi.setPresenterHasSubmittedASession(presentersIds[0]);
            // So that sessions button in header shows up
            reloadAuthState();
            navigate('/session/' + sessionId);
        });
}


export default function CreateSession() {
    const stateSessionSubmissionPromise = useMemo(() => dbApi.getSessionSubmissionState(), []);
    const [stateSessionSubmission, setStateSessionSubmission] = useState(false)

    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [xpFactor, setXpFactor] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [secondPresenter, setSecondPresenter] = useState();
    const presentersPromise = useMemo(() => dbApi.getAllPresenters(), []);
    const [allPresenters, setAllPresenters] = useState([]);
    const [filteredPresenters, setFilteredPresenters] = useState([]);
    const {user, currentPresenter, reloadAuthState} = useAuthContext();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const target = useRef(null);
    const stateXpFactorPromise = useMemo(() => dbApi.getXpFactorActiveState(), []);
    const [stateXpFactor, setStateXpFactor] = useState(false);
    
	//SET CHAR LIMIT
	const maxCharLimits = { title: 70, subTitle: 80, desc: 500 };

    useEffect(() => {
        stateSessionSubmissionPromise.then((promisedState) => {
            setStateSessionSubmission(promisedState);
        });
    }, [stateSessionSubmissionPromise]);

    useEffect(() => {
        presentersPromise?.then((promisedPresenters) => {
            if (!promisedPresenters) return;
            if (promisedPresenters.length === 0) return;
            if (!currentPresenter) return;
            setAllPresenters(promisedPresenters);
            let filtered = promisedPresenters.filter(p => currentPresenter?.id !== p.id);
            filtered = filtered.map((filteredPresenter) => {
                return {
                    text: filteredPresenter.firstName + " " + filteredPresenter.lastName,
                    object: filteredPresenter
                }
            });
            filtered.unshift({
                text: "No second presenter",
                object: null,
            });
            setFilteredPresenters(filtered);
        });
        // eslint-disable-next-line
    }, [presentersPromise, allPresenters, currentPresenter]);
    useEffect(() => {
        stateXpFactorPromise.then((promisedState) => setStateXpFactor(promisedState));
      }, [stateXpFactorPromise]);
    

 return (
    <Container fluid style={{maxWidth:"75%"}}>
   <Card className={"session-submission-info m-1 my-4"}>
    <Card.Body>
     {!stateSessionSubmission ? (
      <>
       <h3>
        Session Submission is closed! Only maintainers can submit sessions.
       </h3>
       <h3>You can activate the session submission in the Configuration.</h3>
      </>
     ) : (
      <h3>Session submission is done in 2 steps:</h3>
     )}
     {!stateSessionSubmission && <p>Session submission is done in 2 steps.</p>}
     <Col className={"ms-2"}>
      <p>
       -Step 1: Submit a high level proposal
       <br />
       -Step 2: Iteratively and incrementally add details to your proposal as
       you get questions and feedback
      </p>
     </Col>
     <p>
      Help other presenters develop their proposals, based on peer to peer
      feedback. You can give feedback on other people's proposals and improve
      your proposal based on the feedback you receive. In this way, we will come
      to a set of quality session proposals in a transparent, community driven
      way.
     </p>
    </Card.Body>
   </Card>
   {user && (stateSessionSubmission || currentPresenter.role === "admin") && (
    <FormCard
     title={"Create Session"}
     className={"m-1"}
     headerClassName={"create-session-form-card"}
    >
     <Form
      className="create-session-form row g-3"
      onSubmit={e => {
       e.preventDefault();
       setSubmitted(true);
       handleSubmit(
        title,
        subtitle,
        shortDescription,
        xpFactor,
        [currentPresenter, secondPresenter],
        setSubmitted,
        reloadAuthState,
        navigate
       );
      }}
     >
      <Form.Group className="col-12">
       <Form.Label>Title:</Form.Label>
       <Form.Control
        className={"session-input-field"}
        placeholder={"The title of your session"}
        type="text"
        maxLength={maxCharLimits.title}
        onChange={e => {
         const newValue = e.target.value;
         setTitle(newValue);
        }}
        required
       />
       <Badge className="mt-3" bg={`danger`}>
        {title.length}/{maxCharLimits.title}
       </Badge>
      </Form.Group>
      <Form.Group className="col-12">
       <Form.Label>Subtitle:</Form.Label>

       <Form.Control
        className={"session-input-field"}
        placeholder={
         "Sometimes your title is catchy but doesn't describe the content of your session. A subtitle can help. Keep it short!"
        }
        type="text"
        maxLength={maxCharLimits.subTitle}
        onChange={e => {
         const newValue = e.target.value;
         setSubtitle(newValue);
        }}
        required
       />
       <Badge className="mt-3" bg={`danger`}>
        {subtitle.length}/{maxCharLimits.subTitle}
       </Badge>
      </Form.Group>
                     {stateXpFactor && (
        <Form.Group className="col-sm-12 col-md-6 col-lg-2">
        <Form.Label>
         XP Factor: <strong>{xpFactor}</strong>{" "}
        </Form.Label>
        <Button
         size="sm"
         className={"button-standard xp-factor-info-button m-1"}
         ref={target}
         onClick={() => setShow(!show)}
        >
         <FaInfo style={{ marginBottom: "0.5vh" }} />
        </Button>
        <Overlay target={target.current} show={show} placement="right">
         {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
           {...props}
           className={"xp-factor-info-text"}
           style={{
            position: "absolute",
            padding: "2px 10px",
            borderRadius: 3,
            ...props.style,
           }}
          >
           The XP factor is indicates the extremeness of your session. How
           thought provoking, exiting, eXtreme, wtf? is your session?
          </div>
         )}
        </Overlay>
        <Form.Control
         className={"xp-factor-slider shadow-none form-range"}
         min={0}
         defaultValue={0}
         max={10}
         type="range"
         placeholder={
          "The XP factor is indicates the extremeness of your session. How thought provoking, exiting, eXtreme, wtf? is your session?"
         }
         onChange={e => setXpFactor(e.target.value)}
         required
        />
       </Form.Group> )}
      <Form.Group className="col-lg-3 col-md-6 col-sm-12">
       <Form.Label>Second presenter:</Form.Label>
       <DropdownWithFilter
        items={filteredPresenters}
        filterFn={text => {
         let filtered = allPresenters.filter(filteredPresenter =>
          (filteredPresenter.firstName + " " + filteredPresenter.lastName)
           .toUpperCase()
           .includes(text.toUpperCase())
         );
         filtered = filtered.filter(p => currentPresenter?.id !== p.id);
         filtered = filtered.map(filteredPresenter => {
          return {
           text: filteredPresenter.firstName + " " + filteredPresenter.lastName,
           object: filteredPresenter,
          };
         });
         filtered.unshift({
          text: "No second presenter",
          object: null,
         });
         setFilteredPresenters(filtered);
        }}
        onClickFn={presenter => {
         setSecondPresenter(presenter);
        }}
        title={
         secondPresenter
          ? secondPresenter.firstName + " " + secondPresenter.lastName
          : "Second presenter"
        }
        className={"button-standard create-session-second-presenter-button"}
       />
      </Form.Group>

      <Form.Group className="input-group">
       <Form.Label className="col-12">Short description:</Form.Label>
       <Container className="position-relative">
        <Form.Control
         style={{ height: "6em", borderRadius: "5px" }}
         className={"textAreaSessionEdit"}
         placeholder={
          "This will appear on the website, and it will also be displayed on the wall during the conference. This is a public description."
         }
         as="textarea"
         maxLength={maxCharLimits.desc}
         onChange={e => {
          const newValue = e.target.value;

          setShortDescription(newValue);
         }}
         required
        />
        <Badge className="mt-3" bg={`danger`}>
         {shortDescription.length}/{maxCharLimits.desc}
        </Badge>
       </Container>
      </Form.Group>
      <div className="col-12">
       {!submitted ? (
        <FormSubmitButton className={"button-standard"}>
         Create session
        </FormSubmitButton>
       ) : (
        <FormSubmitButton className={"button-standard"} disabled>
         Create session
        </FormSubmitButton>
       )}
      </div>
     </Form>
    </FormCard>
   )}
  </Container>
 );
}
