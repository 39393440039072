import React from "react";

export function FormSubmitButton(props) {
        const { onClick, children, className, disabled, ...rest } = props;
    
        return (
            <button disabled={disabled} className={className + " btn"} onClick={onClick} {...rest}>
                {children}
            </button>
        );
}
  




