import { initializeApp } from "firebase/app";
import {
 addDoc,
 collection,
 connectFirestoreEmulator,
 deleteDoc,
 deleteField,
 doc,
 getCountFromServer,
 getDoc,
 getDocs,
 getDocsFromServer,
 getFirestore,
 orderBy,
 query,
 serverTimestamp,
 setDoc,
 updateDoc,
  where,

} from "firebase/firestore";
import {
  connectAuthEmulator,
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateEmail,
} from "firebase/auth";
import {
 connectStorageEmulator,
 getDownloadURL,
 getStorage,
 ref,
  uploadBytes,
 getMetadata
} from "firebase/storage";
import { limit, startAfter } from "firebase/firestore";
import { data, get } from "jquery";
import { set } from "firebase/database";
import { arrayRemove } from "firebase/firestore";

const firebaseConfig = {
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_URL,
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const fbFirestore = getFirestore(app);
export const fbAuth = getAuth(app);
export const fbStorage = getStorage(app);

if (process.env.REACT_APP_FIREBASE_EMULATOR_HOST) {
  connectFirestoreEmulator(
    fbFirestore,
    process.env.REACT_APP_FIREBASE_EMULATOR_HOST,
    process.env.REACT_APP_FIREBASE_EMULATOR_PORT
  );
}
if (process.env.REACT_APP_FIREBASE_AUTH_URL)
  connectAuthEmulator(fbAuth, process.env.REACT_APP_FIREBASE_AUTH_URL);

if (process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_PORT)
  connectStorageEmulator(
    fbStorage,
    process.env.REACT_APP_FIREBASE_EMULATOR_HOST,
    process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_PORT
  );

export const dbApi = {
  getAllSessions: () => getAllSessions(),
  getAllReviews: () => getTable("reviews"),
  getCommentsOnReview: (reviewId) => getCommentsOnReview(reviewId),
  submitComment: (
    submitterId,
    reviewId,
    commentContent,
    sessionId,
    reviewedBy
  ) =>
    submitComment(submitterId, reviewId, commentContent, sessionId, reviewedBy),
  editComment: (commentId, content) => editComment(commentId, content),
  createSession: (title, subtitle, shortDescription, xpFactor, presenters) =>
    createSession(title, subtitle, shortDescription, xpFactor, presenters),
  getReviewsOfSession: (sessionId) => getReviewsOfSession(sessionId),
  postReview: (
    submitterId,
    sessionId,
    whatILike,
    improvementOpportunities,
    sessionScore,
    sessionXpFactor,
    sessionCreatedBy,
    isNew
  ) =>
    postReview(
      submitterId,
      sessionId,
      whatILike,
      improvementOpportunities,
      sessionScore,
      sessionXpFactor,
      sessionCreatedBy,
      isNew
    ),
  editReview: (
    reviewId,
    whatILike,
    improvementOpportunities,
    sessionScore,
    sessionXpFactor
  ) =>
    editReview(
      reviewId,
      whatILike,
      improvementOpportunities,
      sessionScore,
      sessionXpFactor
    ),
  getSession: (sessionId) => getSession(sessionId),
  editSession: (sessionId, session) => editSession(sessionId, session),
  getVotes: () => getTable("votes"),
  getSessionsByIds: (ids) => getSessionsByIds(ids),
  getPresenter: (presenterId) => getPresenter(presenterId),
  getPresenterByUserId: (id) => getPresenterByUserId(id),
  getAllPresenters: () => getAllPresenters(),
  getPresentersByIds: (ids) => getPresentersByIds(ids),
  submitVote: (presenterId, sessionID) => submitVote(presenterId, sessionID),
  getVotesFromPresenter: (presenterId) => getVotesFromPresenter(presenterId),
  hasPresenterVotedOnSession: (presenterId, sessionId) =>
    hasPresenterVotedOnSession(presenterId, sessionId),
  withdrawVote: (presenterId, sessionId) =>
    withdrawVote(presenterId, sessionId),
  getVotingActiveState: () => getVotingActiveState(),
  getSessionSubmissionState: () => getSessionSubmissionState(),
  getSessionEditState: () => getSessionEditState(),
  setVotingActiveState: (state) => setVotingActiveState(state),
  setSessionSubmissionState: (state) => setSessionSubmissionState(state),
  setSessionEditState: (state) => setSessionEditState(state),
  makeAdmin: (role, user) => makeAdmin(role, user),
  getReviewsOfPresenter: (id) => getReviewsOfPresenter(id),
  getSessionsOfPresenter: (id) => getSessionsOfPresenter(id),
  convertTimestampToDate: (timestamp) => convertTimestampToDate(timestamp),
  verifyPresenter: (unverifiedPresenterId, unverifiedPresenterKey, userId) =>
    verifyPresenter(unverifiedPresenterId, unverifiedPresenterKey, userId),
  registerPresenter: (userId, firstName, lastName, email) =>
    registerPresenter(userId, firstName, lastName, email),
  uploadProfilePicture: (file, presenterId) =>
    uploadProfilePicture(file, presenterId),
  setProfilePictureLink: (presenterId, url) =>
    setProfilePictureLink(presenterId, url),
  updatePresenter: (presenter, presenterId) =>
    updatePresenter(presenter, presenterId),
  getProgram: (programId) => getProgram(programId),
  getLocationsOfProgram: (programId) =>
    getTable("programs/" + programId + "/locations"),
  getTimesOfProgram: (programId) =>
    getTable("programs/" + programId + "/times"),
  getCellsOfProgram: (programId) =>
    getCellsOfProgram("programs/" + programId + "/cells"),
  updateMailAsUser: (userId, mailEdit, passwordConfirmation, user) =>
    updateMailAsUser(userId, mailEdit, passwordConfirmation, user),
  updateMailAsAdmin: (mailEdit) => updateMailAsAdmin(mailEdit),
  createProgram: (title, locations, times, matrix) =>
    createProgram(title, locations, times, matrix),
  saveProgram: (program, locations, times, matrix) =>
    saveProgram(program, locations, times, matrix),
  getAllPrograms: () => getTable("programs"),
  setPresenterIdToUserId: (presenterId, userId, sessionId, email) =>
    setPresenterIdToUserId(presenterId, userId, sessionId, email),
  setUserData: (userId, email) => setUserData(userId, email),
  getUserData: (userId) => getUserData(userId),
  setPresenterHasSubmittedASession: (presenterId) =>
    setPresenterHasSubmittedASession(presenterId),
  getPresenterProfilePicture: (presenterId) =>
    getPresenterProfilePicture(presenterId),
  deleteSession: (sessionId) => deleteSession(sessionId),
  downloadPresenterEmails: (callback) => downloadPresenterEmails(callback),
  addEmailToPresenter: (presenterId, email) =>
    addEmailToPresenter(presenterId, email),
  getReviewNotificationsOfPresenter: (presenterId) =>
    getReviewNotificationsOfPresenter(presenterId),
  markReviewAsRead: (reviewId) => markReviewAsRead(reviewId),
  getReview: (reviewId) => getReview(reviewId),
  getNewCommentsForPresenter: (presenterId) =>
    getNewCommentsForPresenter(presenterId),
  markCommentAsRead: (commentId) => markCommentAsRead(commentId),
  getAllSessionsThatAreNotCancelled: () => getAllSessionsThatAreNotCancelled(),
  getReviewsOfNotCancelledSessions: () => getReviewsOfNotCancelledSessions(),
  getAllReviewsQuery: () => getAllReviewsQuery(),
  setReviewsAndCommentsActive: (state) => setReviewsAndCommentsActive(state),
  getReviewsAndCommentsState: () => getReviewsAndCommentsState(),
  setXpFactorActive: (state) => setXpFactorActive(state),
  getXpFactorActiveState: (state) => getXpFactorActiveState(state),
  getAllPresenterEmailsAndIds: () => getAllPresenterEmailsAndIds(),
  getAllComments: () => getAllComments(),
  getOldCommentsForPresenter: (presenterId) => getOldCommentsForPresenter(presenterId),
  getOldReviewNotificationsOfPresenter: (presenterId) => getOldReviewNotificationsOfPresenter(presenterId),
  amountOfVotesCast: () => amountOfVotesCast(),
  setAmountOfVotesCast: (amount) => setAmountOfVotesCast(amount),
  editDashboard: (title, text, image) => editDashboard(title, text, image),
  getDashboard: () => getDashboard(),
  uploadDashboardImage: (file) => uploadDashboardImage(file),
  setDashboardImageLink: (url) => setDashboardImageLink(url),
  getDashboardImage: () => getDashboardImage(),
  getExperienceState: () => getExperienceState(),
  setExperienceState: (state) => setExperienceState(state),
  setVoteForOwnSessionState: (state) => setVoteForOwnSessionState(state),
  getVoteForOwnSessionState: () => getVoteForOwnSessionState(),
  getReviewsVisibleState: () => getReviewsVisibleState(),
  setReviewsVisibleState: (state) => setReviewsVisibleState(state),
  downloadConfirmedSessions: (callback) => downloadConfirmedSessions(callback),
  downloadDraftSessions: (callback) => downloadDraftSessions(callback),
  deleteAccountRequest: (userId, passwordConfirmation, user) => deleteAccountRequest(userId, passwordConfirmation, user),
  getPresentersWithDeletionRequests: () => getPresentersWithDeletionRequests(),
  cancelDeletionRequest: (presenterId) => cancelDeletionRequest(presenterId),
  deletePresenter: (presenterId) => deletePresenter(presenterId),
  deleteAllReviewsFromUser: (presenterId) => deleteAllReviewsFromUser(presenterId),
  deleteAllCommentsFromUser: (presenterId) => deleteAllCommentsFromUser(presenterId),
  deleteAllVotesFromUser: (presenterId) => deleteAllVotesFromUser(presenterId),
};
async function getTable(table) {
  const q = query(collection(fbFirestore, table));
  return getDocs(q).then((docs) => {
    return docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}

async function getAllSessions() {
  const q = query(
    collection(fbFirestore, "sessions"),
    where("verified", "==", true)
  );
  return getDocs(q).then((docs) => {
    return docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}

/////////////////////////////////////////////////////////////////////////
async function getAllSessionsThatAreNotCancelled() {
  const q = query(
    collection(fbFirestore, "sessions"),
    where("verified", "==", true),
    where("state", "!=", "Cancelled")
  );
  return getDocs(q).then((docs) => {
    return docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}

export async function markCommentAsRead(commentId) {
  try {
    await updateDoc(doc(collection(fbFirestore, "comments"), commentId), {
      isNew: false,
    });
  } catch (error) {
    console.error("Error marking comment as read:", error);
  }
}

export async function getNewCommentsForPresenter(presenterId) {
  const q = query(
    collection(fbFirestore, "comments"),
    where("reviewedBy", "==", presenterId)
  );

  return getDocs(q).then((docs) => {
    return docs.docs
      .filter((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data.isNew === true;
      })
      .map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      });
  });
}


export async function getOldCommentsForPresenter(presenterId) {
  const q = query(
    collection(fbFirestore, "comments"),
    where("reviewedBy", "==", presenterId),
    orderBy("dateCreated", "desc"),
    limit(5)
  );

  return getDocs(q).then((docs) => {
    return docs.docs
      .filter((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data.isNew === false && data.commentBy !== presenterId;
      })
      .map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      });
  });
}




async function getCommentsOnReview(reviewId) {
  const q = query(
    collection(fbFirestore, "comments"),
    where("reviewDocID", "==", reviewId)
  );
  return getDocs(q).then((docs) => {
    return docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}

async function submitComment(
  submitterId,
  reviewId,
  commentContent,
  sessionId,
  reviewedBy
) {
  const isNew = submitterId !== reviewedBy; // Check if submitterId is different from reviewedBy

  return addDoc(collection(fbFirestore, "comments"), {
    commentBy: submitterId,
    commentContent: commentContent,
    reviewDocID: reviewId,
    isNew: isNew,
    sessionId: sessionId,
    reviewedBy: reviewedBy,
    dateCreated: serverTimestamp(),
  }).then((reference) => {
    return reference.id;
  });
}

async function editComment(commentId, content) {
  return updateDoc(doc(collection(fbFirestore, "comments"), commentId), {
    commentContent: content,
    dateModified: serverTimestamp(),
  });
}

async function createSession(
  title,
  subtitle,
  shortDescription,
  xpFactor,
  presenters
) {
  const snapshot = await getCountFromServer(
    query(collection(fbFirestore, "sessions"), where("verified", "==", true))
  );
  const data = {
    title: title,
    subtitle: subtitle,
    xpFactor: xpFactor,
    shortDescription: shortDescription,
    created: serverTimestamp(),
    modified: serverTimestamp(),
    verified: true,
    state: "Draft",
    presenters: presenters,
    refNumber: snapshot.data().count + 1,
  };
  return addDoc(collection(fbFirestore, "sessions"), data).then((reference) => {
    return reference.id;
  });
}

export async function getSession(sessionId) {
  try {
    const docRef = doc(fbFirestore, "sessions", sessionId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const session = docSnap.data();
      session.id = docSnap.id;
      return session;
    } else {
    }
  } catch (error) {
    throw error;
  }
}

async function editSession(sessionId, session) {
  delete session.id;
  return updateDoc(doc(fbFirestore, "sessions/" + sessionId), {
    ...session,
    modified: serverTimestamp(),
  });
}

async function getReviewsOfSession(sessionId) {
  const q = query(
    collection(fbFirestore, "reviews"),
    where("sessionDocID", "==", sessionId)
  );
  return getDocs(q).then((docs) => {
    return docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}
export async function getReview(reviewId) {
  try {
    const docRef = doc(fbFirestore, "reviews", reviewId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const review = docSnap.data();
      review.id = docSnap.id;
      return review;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching review:", error);
    throw error;
  }
}

async function postReview(
  submitterId,
  sessionId,
  whatILike,
  improvementOpportunities,
  sessionScore,
  sessionXpFactor
) {
  const sessionDoc = await getDoc(doc(fbFirestore, "sessions", sessionId));
  const sessionData = sessionDoc.data();
  const sessionCreatorId = sessionData.presenters[0];
  const isNew = submitterId !== sessionCreatorId;

  return addDoc(collection(fbFirestore, "reviews"), {
    reviewedBy: submitterId,
    sessionDocID: sessionId,
    sessionCreatedBy: sessionCreatorId,
    whatILike: whatILike,
    improvementOpportunities: improvementOpportunities
      ? improvementOpportunities
      : "",
    sessionScore: parseInt(sessionScore),
    sessionXpFactor: parseInt(sessionXpFactor),
    dateCreated: serverTimestamp(),
    isNew: isNew,
  }).then((reference) => {
    return reference.id;
  });
}

export async function getAllReviewsQuery() {
  const q = query(collection(fbFirestore, "reviews"));
  return getDocs(q).then((docs) => {
    return docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}

export async function getReviewsOfNotCancelledSessions() {
  const reviewsQuery = query(collection(fbFirestore, "reviews"));

  const reviewDocs = await getDocs(reviewsQuery);
  const reviews = reviewDocs.docs.map((doc) => {
    const data = doc.data();
    data.id = doc.id;
    return data;
  });

  const sessionDocIDs = reviews.map((review) => review.sessionDocID);

  const sessionsQuery = query(
    collection(fbFirestore, "sessions"),
    where("state", "!=", "Cancelled")
  );

  const sessionDocs = await getDocs(sessionsQuery);
  const sessions = sessionDocs.docs.map((doc) => {
    const data = doc.data();
    data.id = doc.id;
    return data;
  });

  const reviewsOfNotCancelledSessions = reviews.filter((review) => {
    const session = sessions.find(
      (session) => session.id === review.sessionDocID
    );
    return session && session.state !== "Cancelled";
  });

  return reviewsOfNotCancelledSessions;
}

export async function getReviewNotificationsOfPresenter(presenterId) {
  const q = query(
    collection(fbFirestore, "reviews"),
    where("sessionCreatedBy", "==", presenterId)
  );
  return getDocs(q).then((docs) => {
    return docs.docs
      .filter((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data.isNew === true;
      })
      .map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      });
  });
}
export async function getOldReviewNotificationsOfPresenter(presenterId) {
  const q = query(
    collection(fbFirestore, "reviews"),
    where("sessionCreatedBy", "==", presenterId),
    orderBy("dateCreated", "desc"),
    limit(5)
  );
  return getDocs(q).then((docs) => {
    return docs.docs
      .filter((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data.isNew === false && data.reviewedBy!== presenterId;
      })
      .map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      });
  });
}

export async function markReviewAsRead(reviewId, notification) {
  try {
    if (
      notification &&
      notification.review &&
      notification.review.id === reviewId
    ) {
      
      await updateDoc(doc(collection(fbFirestore, "reviews"), reviewId), {
        isNew: false,
      });
     
    } else {
      console.error(
        "Error marking review as read: Review ID does not match the expected review."
      );
    }
  } catch (error) {
    
  }
}

async function editReview(
  reviewId,
  whatILike,
  improvementOpportunities,
  sessionScore,
  sessionXpFactor
) {
  return updateDoc(doc(collection(fbFirestore, "reviews"), reviewId), {
    whatILike: whatILike,
    improvementOpportunities: improvementOpportunities,
    sessionScore: parseInt(sessionScore),
    sessionXpFactor: parseInt(sessionXpFactor),
    dateModified: serverTimestamp(),
  });
}

async function getSessionsByIds(ids) {
  if (!ids.length) return [];
  let promises = [];
  while (ids.length) {
    const batch = ids.splice(0, 10);
    const q = query(
      collection(fbFirestore, "sessions"),
      where("__name__", "in", batch)
    );
    promises.push(
      getDocs(q).then((docs) => {
        return docs.docs.map((doc) => {
          const data = doc.data();
          data.id = doc.id;
          return data;
        });
      })
    );
  }

  let result = [];
  const promisedResults = await Promise.all(promises);
  promisedResults.forEach((batch) => {
    result.push(...batch);
  });
  return result;
}

export async function getPresenter(presenterId) {
  return await getDoc(doc(fbFirestore, "presenters", presenterId)).then(
    (doc) => {
      const data = doc.data();
      data.id = presenterId;
      return data;
    }
  );
}

export async function getPresenterByUserId(id) {
  const q = query(
    collection(fbFirestore, "presenters"),
    where("user", "==", id)
  );
  return getDocs(q).then((docs) => {
    if (docs.empty) return undefined;
    const data = docs.docs[0].data();
    data.id = docs.docs[0].id;
    return data;
  });
}

// function sortIdsByIds(desiredSort, arrayToSort) {
//     const sortedArray = [];
//     desiredSort.forEach((item, index) => {
//         for (let i = 0; i < arrayToSort.length; i++) {
//             if (desiredSort[index] === arrayToSort[i].id) {
//                 sortedArray.push(arrayToSort[i]);
//                 arrayToSort.splice(i);
//                 break;
//             }
//         }
//     });
//     return sortedArray;
// }

async function getPresentersByIds(ids) {
  if (!ids.length) return [];
  const q = query(
    collection(fbFirestore, "presenters"),
    where("__name__", "in", ids)
  );
  return getDocs(q).then((docs) => {
    const modifiedDocs = docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
    //return sortIdsByIds(ids, modifiedDocs);
    if (ids.length > 1) {
      return modifiedDocs
        .filter((p) => p.firstName && p !== "" && p.firstName !== "")
        .sort((a, b) => {
          if (a && !b) {
            return -1;
          }
          if (!a && b) {
            return 1;
          }
          if (a.id === ids[0]) {
            return -1;
          } else return 1;
        });
    } else {
      return modifiedDocs.filter(
        (p) => p.firstName && p !== "" && p.firstName !== ""
      );
    }
  });
}

async function submitVote(submitterId, sessionID) {
  return addDoc(collection(fbFirestore, "votes"), {
    votedBy: submitterId,
    sessionID: sessionID,
    dateCreated: serverTimestamp(),
  }).then((reference) => {
    return reference.id;
  });
}

async function getVotesFromPresenter(presenterId) {
  if (presenterId !== undefined) {
    const q = query(
      collection(fbFirestore, "votes"),
      where("votedBy", "==", presenterId)
    );

    return getDocs(q).then((docs) => {
      return docs.docs.map((doc) => {
        const data = doc.data();
        data.id = doc.id;
        return data;
      });
    });
  } else {
    return Promise.resolve([]);
  }
}

async function hasPresenterVotedOnSession(presenterId, sessionId) {
  if (!presenterId || !sessionId) return false;
  const q = query(
    collection(fbFirestore, "votes"),
    where("votedBy", "==", presenterId),
    where("sessionID", "==", sessionId)
  );
  return getDocs(q).then((docs) => {
    return docs.empty ? false : docs.docs[0].id;
  });
}

async function withdrawVote(presenterId, sessionId) {
  const q = query(
    collection(fbFirestore, "votes"),
    where("votedBy", "==", presenterId),
    where("sessionID", "==", sessionId)
  );
  return getDocs(q).then((docs) => {
    if (!docs.empty) {
      docs.docs.forEach((vote) => {
        deleteDoc(doc(fbFirestore, "votes", vote.id));
      });
    }
  });
}

async function getVotingActiveState() {
  try {
    const votingActiveDoc = await getDoc(
      doc(fbFirestore, "config", "votingActive")
    );
    const data = votingActiveDoc.data();
    return data.state;
  } catch (error) {
    await setDoc(doc(fbFirestore, "config", "votingActive"), { state: false });
    return false;
  }
}

async function getSessionSubmissionState() {
  try {
    const sessionSubmissionDoc = await getDoc(
      doc(fbFirestore, "config", "sessionSubmission")
    );
    const data = sessionSubmissionDoc.data();
    return data.state;
  } catch (error) {
    await setDoc(doc(fbFirestore, "config", "sessionSubmission"), {
      state: false,
    });
    return false;
  }
}

//// Get the session edit toggle state
async function getSessionEditState() {
  try {
    const sessionEditDoc = await getDoc(
      doc(fbFirestore, "config", "editSessionActive")
    );
    const data = sessionEditDoc.data();
    return data.state;
  } catch (error) {
    await setDoc(doc(fbFirestore, "config", "editSessionActive"), {
      state: false,
    });
    return false;
  }
}

async function makeAdmin(role, user) {
  return updateDoc(doc(collection(fbFirestore, "presenters"), user), {
    role: role,
  });
}

async function setVotingActiveState(state) {
  await setDoc(doc(fbFirestore, "config", "votingActive"), { state: state });
}

async function setSessionSubmissionState(state) {
  await setDoc(doc(fbFirestore, "config", "sessionSubmission"), {
    state: state,
  });
}

//// set the edit session active state
async function setSessionEditState(state) {
  await setDoc(doc(fbFirestore, "config", "editSessionActive"), {
    state: state,
  });
}

async function getReviewsOfPresenter(id) {
  if (!id) return null;
  const batchSize = 10;
  let allReviews = [];

  let querySnapshot = await getDocs(
    query(
      collection(fbFirestore, "reviews"),
      where("reviewedBy", "==", id),
      limit(batchSize)
    )
  );

  let reviews = querySnapshot.docs.map((doc) => {
    const data = doc.data();
    data.id = doc.id;
    return data;
  });
  allReviews = allReviews.concat(reviews);
  while (reviews.length === batchSize) {
    const lastReview = querySnapshot.docs[querySnapshot.docs.length - 1];

    querySnapshot = await getDocs(
      query(
        collection(fbFirestore, "reviews"),
        where("reviewedBy", "==", id),
        startAfter(lastReview),
        limit(batchSize)
      )
    );

    reviews = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
    allReviews = allReviews.concat(reviews);
  }

  return allReviews;
}

async function getSessionsOfPresenter(id) {
  if (!id) return;
  const q = query(
    collection(fbFirestore, "sessions"),
    where("presenters", "array-contains", id)
  );
  return getDocs(q).then((docs) => {
    return docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}

function convertTimestampToDate(timestamp) {
  return timestamp.toDate();
}

async function verifyPresenter(
  unverifiedPresenterId,
  unverifiedPresenterKey,
  userId
) {
  return updateDoc(
    doc(collection(fbFirestore, "presenters"), unverifiedPresenterId),
    {
      user: userId,
      bio: "",
      profilePicture: "",
      twitter: "",
      website: "",
      keyNeededToVerify: deleteField(),
      keyUsedToVerify: unverifiedPresenterKey,
      verified: true,
    }
  );
}

async function registerPresenter(userId, firstName, lastName, email) {
  if (!email) {
    console.error("Email is undefined in registerPresenter");
    throw new Error("Email is undefined in registerPresenter");
  }

  return addDoc(collection(fbFirestore, "presenters"), {
    user: userId,
    firstName,
    lastName,
    email: email,
    bio: "",
    profilePicture: "",
    twitter: "",
    website: "",
    verified: true,
  });
}

async function getAllPresenters() {
  const q = query(
    collection(fbFirestore, "presenters"),
    where("verified", "==", true)
  );
  return getDocs(q).then((docs) => {
    return docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}

async function addEmailToPresenter(presenterId, email) {
  if (!email || email === "") {
    console.error("Email is undefined in addEmailToPresenter");
    throw new Error("Email is undefined in addEmailToPresenter");
  }
  return updateDoc(doc(collection(fbFirestore, "presenters"), presenterId), {
    email: email,
  });
}



async function updatePresenter(presenter, presenterId) {
  if (presenter && presenterId) {
    await updateDoc(
      doc(collection(fbFirestore, "presenters"), presenterId),
      presenter
    );
  }
}

async function getProgram(programId) {
  return getDoc(doc(fbFirestore, "programs", programId)).then((doc) => {
    const program = doc.data();
    if (!program) return;
    program.id = doc.id;
    return program;
  });
}

async function getCellsOfProgram(programSessionsPath) {
  const q = query(collection(fbFirestore, programSessionsPath));
  return (await getDocs(q)).docs.map((doc) => {
    const data = doc.data();
    data.id = doc.id;
    return data;
  });
}

async function updateMailAsUser(userId, mailEdit, passwordConfirmation, user) {
  return reauthenticateWithCredential(
    user,
    EmailAuthProvider.credential(user.email, passwordConfirmation)
  ).then(async (creds) => {
    await updateEmail(creds.user, mailEdit);
    await setUserData(user.uid, user.email);
  });
}

async function deleteAccountRequest(presenterId, passwordConfirmation, user) {
  return reauthenticateWithCredential(
    user,
    EmailAuthProvider.credential(user.email, passwordConfirmation)
  ).then(async (creds) => {
    const presenterRef = doc(fbFirestore, "presenters", presenterId);
    const deletionRequestField = { DeletionRequest: true };
    await updateDoc(presenterRef, deletionRequestField);
    await updateDoc(doc(fbFirestore, "presenters", presenterId), {
      uid: user.uid,
    });
  });

 
}

async function updateMailAsAdmin(mailEdit) {
  console.warn("update mail as admin not yet implemented, newMail:", mailEdit);
}

async function createProgram(title, locations, times, matrix) {
  const programRef = await addDoc(collection(fbFirestore, "programs"), {
    title: title,
    created: serverTimestamp(),
    modified: serverTimestamp(),
  });
  const locationsPromises = locations.map((location) =>
    addDoc(collection(fbFirestore, programRef.path + "/locations"), {
      title: location.title,
      x: location.x,
    })
  );
  const timesPromises = times.map((time) =>
    addDoc(collection(fbFirestore, programRef.path + "/times"), {
      time: time.time,
      y: time.y,
    })
  );
  const matrixPromises = matrix.map((column, columnIndex) =>
    column.map((row, rowIndex) => {
      if (!row) return;
      let data = {
        coordinates: {
          x: columnIndex,
          y: rowIndex,
        },
      };
      if (row.coordinates.span) {
        data = {
          coordinates: {
            x: columnIndex,
            y: rowIndex,
            span: true,
          },
        };
      }

      if (row.type === "session")
        data.sessionRef = doc(fbFirestore, "/sessions/" + row.object.id);
      if (row.type === "text") data.text = row.object;
      return addDoc(collection(fbFirestore, programRef.path + "/cells"), data);
    })
  );
  await Promise.all(locationsPromises);
  await Promise.all(timesPromises);
  await Promise.all(matrixPromises);
  return programRef.id;
}

async function saveProgram(program, locations, times, matrix) {
  await updateDoc(doc(fbFirestore, "programs/" + program.id), {
    title: program.title,
    modified: serverTimestamp(),
  });

  // Delete all cells of program
  const oldTimesPromise = getDocsFromServer(
    query(collection(fbFirestore, "/programs/" + program.id + "/times"))
  ).then((oldCellsSnapshot) =>
    oldCellsSnapshot.docs.map((cell) => deleteDoc(cell.ref))
  );
  const oldLocationsPromise = getDocsFromServer(
    query(collection(fbFirestore, "/programs/" + program.id + "/locations"))
  ).then((oldCellsSnapshot) =>
    oldCellsSnapshot.docs.map((cell) => deleteDoc(cell.ref))
  );
  const oldCellsPromise = getDocsFromServer(
    query(collection(fbFirestore, "/programs/" + program.id + "/cells"))
  ).then((oldCellsSnapshot) =>
    oldCellsSnapshot.docs.map((cell) => deleteDoc(cell.ref))
  );
  await Promise.all([oldTimesPromise, oldLocationsPromise, oldCellsPromise]);

  const locationsPromises = locations.map((location) =>
    addDoc(collection(fbFirestore, "/programs/" + program.id + "/locations"), {
      title: location.title,
      x: location.x,
    })
  );
  const timesPromises = times.map((time) =>
    addDoc(collection(fbFirestore, "/programs/" + program.id + "/times"), {
      time: time.time,
      y: time.y,
    })
  );
  const matrixPromises = matrix.map((column, columnIndex) =>
    column.map((row, rowIndex) => {
      if (!row) return;
      let data = {
        coordinates: {
          x: columnIndex,
          y: rowIndex,
        },
      };
      if (row.coordinates.span) {
        data = {
          coordinates: {
            x: columnIndex,
            y: rowIndex,
            span: true,
          },
        };
      }

      if (row.type === "session")
        data.sessionRef = doc(fbFirestore, "/sessions/" + row.object.id);
      if (row.type === "text") data.text = row.object;
      return addDoc(
        collection(fbFirestore, "/programs/" + program.id + "/cells"),
        data
      );
    })
  );
  await Promise.all(locationsPromises);
  await Promise.all(timesPromises);
  await Promise.all(matrixPromises);
}

async function setPresenterIdToUserId(presenterId, userId, sessionId, email) {
  await getPresenter(presenterId)
    .then(async (presenter) => {
      if (presenter.id) {
        delete presenter.id;
      }
      await setDoc(doc(fbFirestore, "presenters", userId), presenter).then(
        async () => {
          await deleteDoc(doc(fbFirestore, "presenters", presenterId));
        }
      );
    })
    .then(async () => {
      await getSession(sessionId).then(async (session) => {
        if (session.presenters[0] === presenterId) {
          await updateDoc(doc(fbFirestore, "sessions", sessionId), {
            presenters: [userId, session.presenters[1]],
          });
        }
        if (session.presenters[1] === presenterId) {
          await updateDoc(doc(fbFirestore, "sessions", sessionId), {
            presenters: [session.presenters[0], userId],
          });
        }
        await setUserData(userId, email);
      });
    });
}

async function setUserData(userId, email) {
  await setDoc(doc(fbFirestore, "userData", userId), { email: email });
}

async function getUserData(userId) {
  return (await getDoc(doc(fbFirestore, "userData", userId))).data();
}

async function setPresenterHasSubmittedASession(presenterId) {
  await updateDoc(doc(fbFirestore, "presenters", presenterId), {
    submittedASession: true,
  });
}






async function setProfilePictureLink(presenterId, url) {
  await updateDoc(doc(collection(fbFirestore, "presenters"), presenterId), {
    profilePicture: url,
  });
}


async function uploadProfilePicture(file, presenterId) {
  const storageRef = ref(
   fbStorage,
   `/profilePictures/${presenterId}/${presenterId}`
  );
  if (!file) {
   console.error("no picture found");
  } else {
   await uploadBytes(storageRef, file);
   return storageRef;
  }
}

async function uploadDashboardImage(file) {
  const storageRef = ref(fbStorage, `/dashboardImages/dashboardImage`);
  if (!file) {
    console.error("no picture found");
  } else {
  
    await uploadBytes(storageRef, file);
    
    const downloadURL = await getDownloadURL(storageRef);
    
    return downloadURL;
  }
}


async function setDashboardImage() {
  const storageRef = ref(fbStorage, `/dashboardImages/dashboardImage`);
  return getDownloadURL(storageRef).then((url) => {
    setDashboardImageLink(url);
  });
}

async function setDashboardImageLink(url) {
  const dashboardDisplayRef = doc(fbFirestore, "config", "dashboardDisplay");
  
 
  await updateDoc(dashboardDisplayRef, { url: url });
}


async function getDashboardImage() {
  const storageRef = ref(fbStorage, `/dashboardImages/dashboardImage`);
  return getDownloadURL(storageRef);

  
}



async function getPresenterProfilePicture(presenterId) {

  const profileRef = ref(fbStorage, `profilePictures/${presenterId}/${presenterId}`);
  const storageRef = ref(fbStorage, `profilePictures/default/defaultProfilePicture.svg`);
  const defaultProfilePictureURL = await getDownloadURL(storageRef);
  try {
    await getMetadata(profileRef);
    return await getDownloadURL(profileRef);
  } catch (e) {   
    setProfilePictureLink(presenterId, defaultProfilePictureURL);
    return await getDownloadURL(
      ref(fbStorage, "profilePictures/default/defaultProfilePicture.svg")
    );
  }
}

const deleteSession = async sessionId => {
 try {
  const reviewsCollection = collection(fbFirestore, "reviews");
  const reviewsQuery = query(
   reviewsCollection,
   where("sessionDocID", "==", sessionId)
  );
  const reviewsSnapshot = await getDocs(reviewsQuery);

    const deleteReviewsPromises = reviewsSnapshot.docs.map(
      async (reviewDoc) => {
        await deleteDoc(reviewDoc.ref);
        console.log(`Review with ID ${reviewDoc.id} deleted successfully`);
      }
    );

    await Promise.all(deleteReviewsPromises);
    try {
      const votesCollection = collection(fbFirestore, "votes");
      const votesQuery = query(
        votesCollection,
        where("sessionID", "==", sessionId)
      );
      const votesSnapshot = await getDocs(votesQuery);
      const deleteVotesPromises = votesSnapshot.docs.map(async (voteDoc) => {
        await deleteDoc(voteDoc.ref);
      });
      await Promise.all(deleteVotesPromises);
    } catch (error) {
      console.error("Error deleting votes:", error);
    }

    try {
      const commentsCollection = collection(fbFirestore, "comments");
      const commentsQuery = query(
        commentsCollection,
        where("sessionId", "==", sessionId)
      );
      const commentsSnapshot = await getDocs(commentsQuery);
      const deleteCommentsPromises = commentsSnapshot.docs.map(
        async (commentDoc) => {
          await deleteDoc(commentDoc.ref);
        }
      );
      await Promise.all(deleteCommentsPromises);
    } catch (error) {
      console.error("Error deleting comments:", error);
    }

    const sessionRef = doc(fbFirestore, "sessions", sessionId);
    await deleteDoc(sessionRef);
  } catch (e) {
    console.error(`Error deleting session with ID ${sessionId}:`, e);
    throw e;
  }
};

async function downloadPresenterEmails(callback) {
  try {
   const presentersCollection = collection(fbFirestore, "presenters");
   const snapshot = await getDocs(presentersCollection);
 
    const presenterData = [];
    presenterData.push(`firstName ,lastName,email`);
   snapshot.forEach(doc => {
    const data = doc.data();
    const email = data.email || "";
    const firstName = data.firstName || "";
    const lastName = data.lastName || "";
     const cleanedEmail = email.replace(/\s/g, "");
    
     if (cleanedEmail) {
      
     presenterData.push(`${firstName} ,${lastName},${cleanedEmail}`);
    }
   });
   if (presenterData.length === 0) {
    console.warn("No valid data found.");
    if (callback) {
     callback(new Error("No valid data found."));
    }
    return;
   }
   const csvText = presenterData.join("\n");
   const blob = new Blob([csvText], { type: "text/plain" });
   const a = document.createElement("a");
   a.href = URL.createObjectURL(blob);
   a.download = "presenter_emails.csv";
   a.style.display = "none";
   document.body.appendChild(a);
   a.click();
   document.body.removeChild(a);
   if (callback) {
    callback(null);
   }
  } catch (error) {
   console.error("Error fetching presenter data:", error.message);
   if (callback) {
    callback(error);
   }
  }
 }
 


export async function getVotes() {
  const q = query(collection(fbFirestore, "votes"));
  return getDocs(q).then((docs) => {
    return docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}

async function getReviewsAndCommentsState() {
  try {
    const reviewsAndCommentsActiveDoc = await getDoc(
      doc(fbFirestore, "config", "reviewsAndCommentsActive")
    );
    const data = reviewsAndCommentsActiveDoc.data();
    return data.state;
  } catch (error) {
    await setDoc(doc(fbFirestore, "config", "reviewsAndCommentsActive"), { state: false });
    return false;
  }
}

async function setReviewsAndCommentsActive(state) {
  await setDoc(doc(fbFirestore, "config", "reviewsAndCommentsActive"), {
    state: state,
  });
}

async function setXpFactorActive(state) {
  await setDoc(doc(fbFirestore, "config", "xpFactorActive"), {
    state: state,
  });
}

async function getXpFactorActiveState() {
  try {
    const xpFactorActiveDoc = await getDoc(
      doc(fbFirestore, "config", "xpFactorActive")
    );
    const data = xpFactorActiveDoc.data();
    return data.state;
  } catch (error) {
    await setDoc(doc(fbFirestore, "config", "xpFactorActive"), { state: false });
    return false;
  }
}
async function getAllPresenterEmailsAndIds() {
  const q = query(
    collection(fbFirestore, "presenters"),
    where("verified", "==", true)
  );
  const presenterDocs = await getDocs(q);

  const presenterData = presenterDocs.docs.map((doc) => {
    const data = doc.data();
    const firstName = data.firstName || ""; 
    const lastName = data.lastName || ""; 
    return { 
      user: data.user, 
      email: data.email, 
      presenter: doc.id, 
      firstName: firstName, 
      lastName: lastName 
    };
  });

  return presenterData;
}


async function getAllComments() {
  const q = query(collection(fbFirestore, "comments"));
  return getDocs(q).then((docs) => {
    return docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}



async function getAllConfirmedSessions() {
  const q = query(
    collection(fbFirestore, "sessions"),
    where("verified", "==", true),
    where("state", "==", "Confirmed")
  );
  return getDocs(q).then(docs => {
    return docs.docs.map(doc => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}


async function getAllDraftSessions() {
  const q = query(
    collection(fbFirestore, "sessions"),
    where("verified", "==", true),
    where("state", "==", "Draft")
  );
  return getDocs(q).then(docs => {
    return docs.docs.map(doc => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}




async function setAmountOfVotesCast(amount) {
  await setDoc(doc(fbFirestore, "config", "voteCap"), {
    amount: amount,
  });
}




async function amountOfVotesCast() {
  try {
    const voteCapDoc = await getDoc(
      doc(fbFirestore, "config", "voteCap")
    );
    const data = voteCapDoc.data();
    return data.amount;
  } catch (error) {
    console.error("Error fetching vote cap:", error);
    return false;
  }
}


async function editDashboard(title, text, ) {
  const dashboardDisplayRef = doc(fbFirestore, "config", "dashboardDisplay");
  await updateDoc(dashboardDisplayRef, {
    title: title,
    text: text,
    modified: serverTimestamp(),
  });
}

async function getDashboard() {
  try {
    const dashboardDoc = await getDoc(
      doc(fbFirestore, "config", "dashboardDisplay")
    );
    const data = dashboardDoc.data();
    return data;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    return false;
  }
}




async function assignRoleToUser(userId, role) {
  await updateDoc(doc(fbFirestore, "presenters", userId), {
    role: role,
  });
}



async function getExperienceState() {
  try {
    const experienceDoc = await getDoc(
      doc(fbFirestore, "config", "experience")
    );
    const data = experienceDoc.data();
    return data.state;
  } catch (error) {
    await setDoc(doc(fbFirestore, "config", "experience"), { state: false });
    return false;
  }
}


async function setExperienceState(state) {
  await setDoc(doc(fbFirestore, "config", "experience"), {
    state: state,
  });
}


async function setVoteForOwnSessionState(state) {
  await setDoc(doc(fbFirestore, "config", "voteForOwnSession"), {
    state: state,
  });
}

async function getVoteForOwnSessionState() {
  try {
    const voteForOwnSessionDoc = await getDoc(
      doc(fbFirestore, "config", "voteForOwnSession")
    );
    const data = voteForOwnSessionDoc.data();
    return data.state;
  } catch (error) {
    await setDoc(doc(fbFirestore, "config", "voteForOwnSession"), { state: false });
    return false;
  }
}


async function getReviewsVisibleState() {
  try {
    const reviewsVisibleDoc = await getDoc(
      doc(fbFirestore, "config", "reviewsVisible")
    );
    const data = reviewsVisibleDoc.data();
    return data.state;
  } catch (error) {
    await setDoc(doc(fbFirestore, "config", "reviewsVisible"), { state: false });
    return false;
  }
} 

async function setReviewsVisibleState(state) {
  await setDoc(doc(fbFirestore, "config", "reviewsVisible"), {
    state: state,
  });
}



async function downloadConfirmedSessions() {
  try {
    const sessionsCollection = collection(fbFirestore, "sessions");
    const sessionsQuery = query(
      sessionsCollection,
      where("verified", "==", true),
      where("state", "==", "Confirmed")
    );
    const sessionsSnapshot = await getDocs(sessionsQuery);
    const sessionsData = sessionsSnapshot.docs.map((sessionDoc) => {
      const data = sessionDoc.data();
      return {
        id: sessionDoc.id,
        title: data.title,
        presenters: data.presenters,
      };
    });
    const presentersCollection = collection(fbFirestore, "presenters");
    const presentersSnapshot = await getDocs(presentersCollection);
    const presentersData = presentersSnapshot.docs.map((presenterDoc) => {
      const data = presenterDoc.data();
      return {
        id: presenterDoc.id,
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        email: data.email || "",
      };
    });

    const presenterData = [];
    presenterData.push(`Session Title,Presenter 1,Presenter 1 Email,Presenter 2,Presenter 2 Email`);
    sessionsData.forEach((session) => {
      const presenter1 = presentersData.find((presenter) => presenter.id === session.presenters[0]) || {};
      const presenter2 = presentersData.find((presenter) => presenter.id === session.presenters[1]) || {};
      presenterData.push(`${session.title},${presenter1.firstName} ${presenter1.lastName},${presenter1.email},${presenter2.firstName} ${presenter2.lastName},${presenter2.email}`);
    });

    if (presenterData.length === 0) {
      console.warn("No valid data found.");
      return;
    }

    const csvText = presenterData.join("\n");
    const blob = new Blob([csvText], { type: "text/plain" });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "Confirmed_sessions.csv";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error fetching presenter data:", error.message);
  }
}

async function downloadDraftSessions() {
  try {
    const sessionsCollection = collection(fbFirestore, "sessions");
    const sessionsQuery = query(
      sessionsCollection,
      where("verified", "==", true),
      where("state", "==", "Draft")
    );
    const sessionsSnapshot = await getDocs(sessionsQuery);
    const sessionsData = sessionsSnapshot.docs.map((sessionDoc) => {
      const data = sessionDoc.data();
      return {
        id: sessionDoc.id,
        title: data.title,
        presenters: data.presenters,
      };
    });
    const presentersCollection = collection(fbFirestore, "presenters");
    const presentersSnapshot = await getDocs(presentersCollection);
    const presentersData = presentersSnapshot.docs.map((presenterDoc) => {
      const data = presenterDoc.data();
      return {
        id: presenterDoc.id,
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        email: data.email || "",
      };
    });

    const presenterData = [];
    presenterData.push(`Session Title,Presenter 1,Presenter 1 Email,Presenter 2,Presenter 2 Email`);
    sessionsData.forEach((session) => {
      const presenter1 = presentersData.find((presenter) => presenter.id === session.presenters[0]) || {};
      const presenter2 = presentersData.find((presenter) => presenter.id === session.presenters[1]) || {};
      presenterData.push(`${session.title},${presenter1.firstName} ${presenter1.lastName},${presenter1.email},${presenter2.firstName} ${presenter2.lastName},${presenter2.email}`);
    });

    if (presenterData.length === 0) {
      console.warn("No valid data found.");
      return;
    }

    const csvText = presenterData.join("\n");
    const blob = new Blob([csvText], { type: "text/plain" });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "Draft_sessions.csv";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error fetching presenter data:", error.message);
  }
}


async function getPresentersWithDeletionRequests() {
  const q = query(collection(fbFirestore, "presenters"), where("DeletionRequest", "==", true));
  return getDocs(q).then((docs) => {
    return docs.docs.map((doc) => {
      const data = doc.data();
      data.id = doc.id;
      return data;
    });
  });
}



async function cancelDeletionRequest(presenterId) {
  await updateDoc(doc(fbFirestore, "presenters", presenterId), {
    DeletionRequest: false,
    uid: "",
  });
}


/* async function deletePresenter(presenterId) {

  const sessionsCollection = collection(fbFirestore, "sessions");
  const sessionsQuery = query(
    sessionsCollection,
    where("presenters", "array-contains", presenterId)
  );
  const sessionsSnapshot = await getDocs(sessionsQuery);
  const deletePresenterPromises = sessionsSnapshot.docs.map(async (sessionDoc) => {
    const sessionData = sessionDoc.data();
    const presenters = sessionData.presenters.filter((presenter) => presenter !== presenterId);
    await updateDoc(sessionDoc.ref, {
      presenters: undefined,
    });
  });
  await Promise.all(deletePresenterPromises);
  deleteAllReviewsFromUser(presenterId);
  deleteAllCommentsFromUser(presenterId);
  deleteAllVotesFromUser(presenterId);
  await deleteDoc(doc(fbFirestore, "presenters", presenterId));
} */


async function deletePresenter(presenterId) {
  const sessionsCollection = collection(fbFirestore, "sessions");
  const sessionsQuery = query(
    sessionsCollection,
    where("presenters", "array-contains", presenterId)
  );
  const sessionsSnapshot = await getDocs(sessionsQuery);
  const deletePresenterPromises = sessionsSnapshot.docs.map(async (sessionDoc) => {
    await updateDoc(sessionDoc.ref, {
      presenters: "",
    });
  });
  await Promise.all(deletePresenterPromises);
  await deleteAllReviewsFromUser(presenterId);
  await deleteAllCommentsFromUser(presenterId);
  await deleteAllVotesFromUser(presenterId);
  await deleteDoc(doc(fbFirestore, "presenters", presenterId));
}

async function deleteAllReviewsFromUser(presenterId) {
  const q = query(
    collection(fbFirestore, "reviews"),
    where("reviewedBy", "==", presenterId),
  );
  return getDocs(q).then((docs) => {
    if (!docs.empty) {
      docs.docs.forEach((review) => {
        deleteDoc(doc(fbFirestore, "reviews", review.id));
      });
    }
  });
}

async function deleteAllCommentsFromUser(presenterId) {
  const q = query(
    collection(fbFirestore, "comments"),
    where("commentedBy", "==", presenterId),
  );
  return getDocs(q).then((docs) => {
    if (!docs.empty) {
      docs.docs.forEach((comment) => {
        deleteDoc(doc(fbFirestore, "comments", comment.id));
      });
    }
  });
}



async function deleteAllVotesFromUser(presenterId) {
    const q = query(
      collection(fbFirestore, "votes"),
      where("votedBy", "==", presenterId),
     
    );
    return getDocs(q).then((docs) => {
      if (!docs.empty) {
        docs.docs.forEach((vote) => {
          deleteDoc(doc(fbFirestore, "votes", vote.id));
        });
      }
    });
  }
  




