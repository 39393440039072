import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/authContext';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { sendEmailVerification } from 'firebase/auth'; 

export function UserOnly(props) {
  const { user, loading, currentPresenter } = useAuthContext();
  const [verificationSent, setVerificationSent] = useState(false);
  const [showLoading, setShowLoading] = useState(true); 
  const navigate = useNavigate();

  useEffect(() => {
    const verificationSentFromStorage = sessionStorage.getItem('verificationSent');
    if (verificationSentFromStorage === 'true') {
      setVerificationSent(true);
    }

    
  setShowLoading(false)
  }, []);

  const resendVerificationEmail = async () => {
    try {
      await sendEmailVerification(user);
      setVerificationSent(true);
      sessionStorage.setItem('verificationSent', 'true');
    } catch (error) {
      console.error('Error resending verification email:', error.message);
    }
  };

  if (showLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '5rem', fontSize: '4rem', color: '#ffffff' }}>Loading...</div>
    );
  }

  if (user && currentPresenter && user.emailVerified) {
    return <>{props.children}</>;
  } else if (loading) {
    return (
      <>
        <h1 style={{ textAlign: 'center', marginTop: '5rem', fontSize: '4rem', color: '#ed1c24' }}>
          {user ? 'Please verify your account to access this page!' : 'Please log in to access this page!'}
        </h1>
        <div style={{ textAlign: 'center' }}>
          {user && !verificationSent ? (
            <Button
              onClick={resendVerificationEmail}
              style={{
                textAlign: 'center',
                marginTop: '15%',
                fontSize: '3rem',
                borderRadius: '0.5em',
              }}
              className={'btn button-standard'}
            >
              Resend Verification Email
            </Button>
          ) : (
            <Button
              onClick={() => navigate('/login')}
              style={{ textAlign: 'center', marginTop: '15%', fontSize: '3rem', borderRadius: '0.5em' }}
              className={'btn button-standard'}
            >
              Login
            </Button>
          )}
        </div>
      </>
    );
  } else {
    return null;
  }
}
