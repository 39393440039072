import { Routes, Route, BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import "./styles/global.css";
import HomePage from "./pages/HomePage";
import SessionsPage from "./pages/SessionsPage";
import LogInPage from "./pages/LogInPage";
import CreateSessionPage from "./pages/CreateSessionPage";
import SessionViewPage from "./pages/SessionViewPage";
import { AuthProvider } from "./contexts/authContext";
import { Layout, PublicLayout } from "./pages/Layout";
import SessionEditPage from "./pages/SessionEditPage";
import { RegisterPage } from "./pages/RegisterPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { ForgotPasswordConfirmationPage } from "./pages/ForgotPasswordConfirmationPage";
import { EmailActionPage } from "./pages/EmailActionPage";
import { ResetPasswordSuccessPage } from "./pages/ResetPasswordSuccessPage";
import { DomainProvider } from "./contexts/domainContext";
import { VotesPage } from "./pages/VotesPage";
import { DashboardPage } from "./pages/DashboardPage";
import { ConfigurationPage } from "./pages/ConfigurationPage";
import { ProgramsPage } from "./pages/ProgramsPage";
import { AdminOnly } from "./components/AdminOnly";
import { UserOnly } from "./components/UserOnly";
import { CreateSessionConfirmationPage } from "./pages/CreateSessionConfirmationPage";
import { ProfilePage } from "./pages/ProfilePage";
import { CookieConsentProvider } from "./contexts/CookieContext";
import PresentersPage from "./pages/PresentersPage";
import { StatisticsPage } from "./pages/StatisticsPage";
import { ProgramPage } from "./pages/ProgramPage";
import { ConfirmEmailPage } from "./pages/ConfirmEmailPage";
import { PublicSessionPage } from "./pages/PublicSessionPage";
import { AccountDeletionPage } from "./pages/AccountDeletionPage";


function App() {
  return (
    <AuthProvider>
      <DomainProvider>
        <BrowserRouter>
          <CookieConsentProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <Layout>
                    <HomePage />
                  </Layout>
                }
              />
              <Route
                path="sessions"
                element={
                  <Layout>
                    <UserOnly>
                      <SessionsPage />
                    </UserOnly>
                  </Layout>
                }
              />
              <Route
                path="LogIn"
                element={
                  <Layout>
                    <LogInPage />
                  </Layout>
                }
              />
              <Route
                path="email-action"
                element={
                  <Layout>
                    <EmailActionPage />
                  </Layout>
                }
              />
              <Route
                path="register"
                element={
                  <Layout>
                    <RegisterPage />
                  </Layout>
                }
              />
              <Route
                path="forgot-password"
                element={
                  <Layout>
                    <ForgotPasswordPage />
                  </Layout>
                }
              />
              <Route
                path="forgot-password-confirmation"
                element={
                  <Layout>
                    <ForgotPasswordConfirmationPage />
                  </Layout>
                }
              />
              <Route
                path="reset-password"
                element={
                  <Layout>
                    <ResetPasswordPage />
                  </Layout>
                }
              />
              <Route
                path="reset-password-success"
                element={
                  <Layout>
                    <ResetPasswordSuccessPage />
                  </Layout>
                }
              />
              <Route
                path="confirm-email"
                element={
                  <Layout>
                    <ConfirmEmailPage />
                  </Layout>
                }
              />
              <Route
                path="createSessions"
                element={
                  <Layout>
                    <UserOnly>
                      <CreateSessionPage />
                    </UserOnly>
                  </Layout>
                }
              />
              <Route
                path="create-session-success"
                element={
                  <Layout>
                    <CreateSessionConfirmationPage />
                  </Layout>
                }
              />
              <Route
                path="session/:id"
                element={
                  <Layout>
                    <UserOnly>
                      <SessionViewPage />
                    </UserOnly>
                  </Layout>
                }
              />
              <Route
                path="sessionEdit/:id"
                element={
                  <Layout>
                    <UserOnly>
                      <SessionEditPage />
                    </UserOnly>
                  </Layout>
                }
              />
              <Route
                path="votes"
                element={
                  <Layout>
                    <AdminOnly>
                      <VotesPage />
                    </AdminOnly>
                  </Layout>
                }
              />
              <Route
                path="dashboard"
                element={
                  <Layout>
                    <DashboardPage />
                  </Layout>
                }
              />
              <Route
                path="configuration"
                element={
                  <Layout>
                    <AdminOnly>
                      <ConfigurationPage />
                    </AdminOnly>
                  </Layout>
                }
              />
              <Route
                path="programs"
                element={
                  <Layout>
                    <AdminOnly>
                      <ProgramsPage />
                    </AdminOnly>
                  </Layout>
                }
              />
              <Route
                path="programs/create"
                element={
                  <Layout>
                    <AdminOnly>
                      <ProgramPage isEdit={true} isNew={true} />
                    </AdminOnly>
                  </Layout>
                }
              />
              <Route
                path="programs/view/:id"
                element={
                  <Layout>
                    <AdminOnly>
                      <ProgramPage />
                    </AdminOnly>
                  </Layout>
                }
              />
              <Route
                path="programs/edit/:id"
                element={
                  <Layout>
                    <AdminOnly>
                      <ProgramPage isEdit={true} />
                    </AdminOnly>
                  </Layout>
                }
              />
              <Route
                path="programs/export/:id"
                element={<ProgramPage isExport={true} />}
              />
              <Route
                path={"profile/:presenterId"}
                element={
                  <Layout>
                    <UserOnly>
                      <ProfilePage />
                    </UserOnly>
                  </Layout>
                }
              />
              <Route
                path={"presenters"}
                element={
                  <Layout>
                    <UserOnly>
                      <PresentersPage />
                    </UserOnly>
                  </Layout>
                }
              />
              <Route
                path="statistics"
                element={
                  <Layout>
                    <AdminOnly>
                      <StatisticsPage />
                    </AdminOnly>
                  </Layout>
                }
              />
              <Route
                path="public/session/view/:id"
                element={
                  <PublicLayout>
                    <PublicSessionPage />
                  </PublicLayout>
                }
              />
              <Route
                path="deleteAccount"
                element={
                  <Layout>
                    <UserOnly>
                     <AccountDeletionPage />
                    </UserOnly>
                  </Layout>
                }
              />
            </Routes>
          </CookieConsentProvider>
        </BrowserRouter>
      </DomainProvider>
    </AuthProvider>
  );
}

export default App;
