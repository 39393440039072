import { Container, Navbar, Nav, NavDropdown, Button, ListGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./HeaderColors.css";
import { useContext, useMemo, useState, useEffect, useRef } from "react";
import { useAuthContext } from "../contexts/authContext";
import { fbAuth } from "../services/firebase";
import { HeaderActivePageContext } from "../contexts/headerActivePageContext";
import { Link } from "react-router-dom";
import { useWindowSize } from "../hooks/useWindowSize";
import Badge from "react-bootstrap/Badge";
import { getReviewNotificationsOfPresenter, getSession, getPresenter, markReviewAsRead, getNewCommentsForPresenter, markCommentAsRead, getOldReviewNotificationsOfPresenter, getOldCommentsForPresenter } from "../services/firebase";
import { dbApi } from "../services/firebase";
export function Header() {
 const [activeLink, setActiveLink] = useState("");
 const [expanded, setExpanded] = useState(false);
 const [showNotifications, setShowNotifications] = useState(false);
 const [hasNewNotifications, setHasNewNotifications] = useState(false);
 const [reviewNotifications, setReviewNotifications] = useState([]);
 const [commentNotifications, setCommentNotifications] = useState([]);
 const [oldReviewNotifications, setOldReviewNotifications] = useState([]);
 const [oldCommentNotifications, setOldCommentNotifications] = useState([]);
 const activePage = useContext(HeaderActivePageContext);
 const { user, currentPresenter } = useAuthContext();
 const windowSize = useWindowSize();
 const showAdminDropdown = useMemo(() => windowSize.width >= 992 && windowSize.width < 1400, [windowSize]);
  const elementRef = useRef(null);
  const [accountDeletionRequestList, setAccountDeletionRequestList] = useState([]);
  const accountDeletionRequestListPromise = useMemo(() => dbApi.getPresentersWithDeletionRequests(), []);
  const requests = accountDeletionRequestList;

  useEffect(() => {
    accountDeletionRequestListPromise.then((requests) => {
      setAccountDeletionRequestList(requests);
    }
    );
  }, [accountDeletionRequestListPromise]);

  function accountDeletionRequestnotification() {
   
    if (requests.length === 0) {
      return <div></div>;
    }
    return (
        <div>
            {requests.map(request => (
              <div style={{backgroundColor:"#ff8282", border:"1em",  padding:"1em"}} key={request.id}>
                <Link style={{ color: "#5c1e1e" }} to={`/profile/${request.id}`}><strong>{request.firstName} {request.lastName}</strong></Link> <span> requested to delete their account</span>
                <br />
                <Link style={{ color: "#5c1e1e" }} to={`/configuration?scrollTo=${request.id}`}><strong>View</strong></Link>
              </div>
              
            ))}
        </div>
    );
}

  

 useEffect(() => {
  async function fetchNotifications() {
   try {
    if (user && currentPresenter) {
     const [
      reviewNotificationsData,
      commentNotificationsData,
      oldReviewNotificationsData,
      oldCommentNotificationsData
     ] = await Promise.all([
      getReviewNotificationsOfPresenter(currentPresenter.id),
      getNewCommentsForPresenter(currentPresenter.id),
      getOldReviewNotificationsOfPresenter(currentPresenter.id),
      getOldCommentsForPresenter(currentPresenter.id, true)
     ]);

     const reviewNotificationsWithDetails = await Promise.all(
      reviewNotificationsData.map(async review => {
       const [session, reviewer] = await Promise.all([
        getSession(review.sessionDocID),
        getPresenter(review.reviewedBy)
       ]);
       return { session, reviewer, review, id: review.id };
      })
     );

     const commentNotificationsWithDetails = await Promise.all(
      commentNotificationsData.map(async comment => {
       const [session, commenter] = await Promise.all([
        getSession(comment.sessionId),
        getPresenter(comment.commentBy)
       ]);
       return { session, commenter, comment, id: comment.id };
      })
     );

     const oldReviewNotificationsWithDetails = await Promise.all(
      oldReviewNotificationsData.map(async review => {
       const [session, reviewer] = await Promise.all([
        getSession(review.sessionDocID),
        getPresenter(review.reviewedBy)
       ]);
       return { session, reviewer, review, id: review.id };
      })
     );

     const oldCommentNotificationsWithDetails = await Promise.all(
      oldCommentNotificationsData.map(async comment => {
       const [session, commenter] = await Promise.all([
        getSession(comment.sessionId),
        getPresenter(comment.commentBy)
       ]);
       return { session, commenter, comment, id: comment.id };
      })
     );

     setOldReviewNotifications(oldReviewNotificationsWithDetails);
     setOldCommentNotifications(oldCommentNotificationsWithDetails);
     setReviewNotifications(reviewNotificationsWithDetails);
     setCommentNotifications(commentNotificationsWithDetails);
     setHasNewNotifications(reviewNotificationsWithDetails.length > 0 || commentNotificationsWithDetails.length > 0);
    }
   } catch (error) {
    console.error("Error fetching notifications:", error);
   }
  }

  fetchNotifications();

  return () => {};
 }, [user, currentPresenter, currentPresenter?.id]);

 function toggleNotifications() {
  setShowNotifications(prevState => !prevState);
   setHasNewNotifications(false);
   
 }

 function handleNavigation(navigatedTo) {
  setActiveLink(navigatedTo);
  setExpanded(false);
 }

 async function handleNotificationClick(Id) {
  try {
   const reviewsToMarkAsRead = reviewNotifications.filter(notification => notification.review.id === Id);

   const commentsToMarkAsRead = commentNotifications.filter(notification => notification.comment.id === Id);
   const oldReviewsToMarkAsRead = oldReviewNotifications.filter(notification => notification.review.id === Id);
   const oldCommentsToMarkAsRead = oldCommentNotifications.filter(notification => notification.comment.id === Id);

   const markReviewsPromise = Promise.all(
    reviewsToMarkAsRead.map(async notification => {
     await markReviewAsRead(notification.review.id, notification);
     window.location.href = `/session/${notification.session.id}?scrollTo=${Id}`;
     elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    })
   );

   const markCommentsPromise = Promise.all(
    commentsToMarkAsRead.map(async notification => {
     await markCommentAsRead(notification.comment.id);
     window.location.href = `/session/${notification.session.id}?scrollTo=${Id}`;
     elementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    })
    );
    
    

   await Promise.all([markReviewsPromise, markCommentsPromise]);
  } catch (error) {
   console.error("Error handling notification click:", error);
  }
 }

 return (
  <HeaderActivePageContext.Provider value={activeLink}>
   <Navbar bg={"light"} expand={"lg"} className="navMenu" expanded={expanded}>
    <Container fluid>
     <LinkContainer
      to={"/dashboard"}
      onClick={() => {
       setActiveLink("home");
       setExpanded(false);
      }}>
      <Navbar.Brand>
       {" "}
       <img src={`/logo.png`} alt={"logo xp Days"} />
      </Navbar.Brand>
     </LinkContainer>
     <Navbar.Toggle onClick={() => setExpanded(!expanded)} />
     <Navbar.Collapse>
      <Nav className={"me-auto"} variant="tabs">
       <LinkContainer
        to={"//www.xpdaysbenelux.nl/"}
        active={activePage === "home"}
        onClick={() => {
         setActiveLink("home");
         setExpanded(false);
        }}>
        <Nav.Link className={"navItem"}>Home</Nav.Link>
       </LinkContainer>
       <LinkContainer
        to={"/dashboard"}
        active={activePage === "dashboard"}
        onClick={() => {
         setActiveLink("dashboard");
          setExpanded(false);
          // eslint-disable-next-line no-restricted-globals
        
        }}>
        <Nav.Link className={"navItem"}>Dashboard</Nav.Link>
       </LinkContainer>
       {user && currentPresenter && (currentPresenter.submittedASession || currentPresenter.role === "admin" || currentPresenter.role === "reviewer") && (
        <LinkContainer
         to={"/sessions"}
         active={activePage === "sessions"}
         onClick={() => {
          setActiveLink("sessions");
          setExpanded(false);
         }}>
         <Nav.Link className={"navItem"}>Sessions</Nav.Link>
        </LinkContainer>
       )}
{user && user.emailVerified && (currentPresenter?.role !== "reviewer") && (        <LinkContainer
         to={"/createSessions"}
         active={activePage === "createsessions"}
         onClick={() => {
          setActiveLink("createsessions");
          setExpanded(false);
         }}>
         <Nav.Link className={"navItem"}>Create Session</Nav.Link>
        </LinkContainer>
       )}
       {user && currentPresenter && (currentPresenter.submittedASession || currentPresenter.role === "admin" || currentPresenter.role === "reviewer") && (
        <LinkContainer
         to={"/presenters"}
         active={activePage === "presenters"}
         onClick={() => {
          setActiveLink("presenters");
          setExpanded(false);
         }}>
         <Nav.Link className={"navItem"}>presenters</Nav.Link>
        </LinkContainer>
       )}
       {user && currentPresenter?.role === "admin" && (
        <>
         {showAdminDropdown && (
          <NavDropdown className={"navItem"} title={"Admin"} active={["presenters", "votes", "programs", "configuration"].includes(activeLink)}>
           <NavDropdown.Item as={Link} to={"/presenters"} onClick={() => handleNavigation("presenters")}>
            Presenters
           </NavDropdown.Item>
           <NavDropdown.Item as={Link} to={"/votes"} onClick={() => handleNavigation("votes")}>
            Votes
           </NavDropdown.Item>
           <NavDropdown.Item as={Link} to={"/programs"} onClick={() => handleNavigation("programs")}>
            Programs
           </NavDropdown.Item>
           <NavDropdown.Item as={Link} to={"/configuration"} onClick={() => handleNavigation("configuration")}>
            Configuration
           </NavDropdown.Item>
           <NavDropdown.Item as={Link} to={"/statistics"} onClick={() => handleNavigation("statistics")}>
            Statistics
           </NavDropdown.Item>
          </NavDropdown>
         )}
         {!showAdminDropdown && (
          <>
           <LinkContainer
            to={"/votes"}
            active={activePage === "votes"}
            onClick={() => {
             setActiveLink("votes");
             setExpanded(false);
            }}>
            <Nav.Link className={"navItem"}>Votes</Nav.Link>
           </LinkContainer>
           <LinkContainer
            to={"/programs"}
            active={activePage === "programs"}
            onClick={() => {
             setActiveLink("programs");
             setExpanded(false);
            }}>
            <Nav.Link className={"navItem"}>Programs</Nav.Link>
           </LinkContainer>
           <LinkContainer
            to={"/configuration"}
            active={activePage === "configuration"}
            onClick={() => {
             setActiveLink("configuration");
             setExpanded(false);
            }}>
            <Nav.Link className={"navItem"}>Configuration</Nav.Link>
           </LinkContainer>
           <LinkContainer
            to={"/statistics"}
            active={activePage === "statistics"}
            onClick={() => {
             setActiveLink("statistics");
             setExpanded(false);
            }}>
            <Nav.Link className={"navItem"}>Statistics</Nav.Link>
           </LinkContainer>
          </>
         )}
        </>
       )}
      </Nav>
     
                 </Navbar.Collapse>
                 <Nav  className="justify-content-end" style={{paddingTop:"8px"}}>
       {" "}
       {!user && (
        <>
         <LinkContainer
          className={"navItem"}
          to={"/register"}
          active={activePage === "register"}
          onClick={() => {
           setActiveLink("register");
           setExpanded(false);
          }}>
          <Nav.Link className={"navItem"}>Register</Nav.Link>
         </LinkContainer>
         <LinkContainer
          to={"/login"}
          active={activePage === "login"}
          onClick={() => {
           setActiveLink("login");
             setExpanded(false);
            }}>
            <Nav.Link className={"navItem"}>Login</Nav.Link>
           </LinkContainer>
          </>
           )}
         {user && (
  <Nav.Link onClick={toggleNotifications}>
    <span className="position-relative">
      <p className="navItem">Notifications</p>
      <i className="fa fa-bell"></i>
      {hasNewNotifications || (requests && Array.isArray(requests) && requests.length !== 0 &&  currentPresenter.role === "admin") ? (
        <Badge
          pill
          bg="danger"
          className="position-absolute top-0 start-100 translate-middle"
        >
          {" "}
          {commentNotifications.length + reviewNotifications.length + (Array.isArray(requests) && currentPresenter.role === "admin" ? requests.length : 0)}{" "}
        </Badge>
      ) : null}
    </span>
  </Nav.Link>
)}

           {user && (
          <LinkContainer to={"/profile/" + currentPresenter.id}>
           <Nav.Link className={"navItem"}>
            {currentPresenter.profilePicture && (
             <img
            height={"30em"}
            width={"30em"}
            style={{
             borderRadius: "50%",
             display: "inline-block",
             verticalAlign: "middle",
            }}
            src={currentPresenter.profilePicture}
            alt={"presenter"}
             />
            )}
            {"  " + currentPresenter.firstName}
            {/*{currentPresenter?.role === "admin" &&*/}
          {/*<> (Admin)</> }*/}
         </Nav.Link>
        </LinkContainer>
       )}
       {user && (
        <LinkContainer
         to={"/dashboard"}
         active={activePage === "logout"}
         onClick={() => {
          fbAuth.signOut();
          setExpanded(false);
         }}>
         <Nav.Link className={"navItem"}>Logout</Nav.Link>
        </LinkContainer>
       )}
       <div className="notifications-container">
        {showNotifications && (
         <ListGroup style={{ position: "fixed", right: "0", maxWidth: "245px", marginRight: "5px" }}>
          {reviewNotifications.concat(commentNotifications).map((notification, index) => (
           <ListGroup.Item action variant="warning" key={index}>
            {notification.session && notification.session.title && (
             <>
              {notification.comment ? (
               <>
                <strong>
                 {notification.commenter.firstName} {notification.commenter.lastName}
                </strong>{" "}
                commented on your review
               </>
                ) : (
                 <>
                <strong>{notification.session.title}</strong> has been reviewed by {notification.reviewer.firstName} {notification.reviewer.lastName}
                {notification.commenter && " and "}
                {notification.commenter && (
                 <strong>
                  {notification.commenter.firstName} {notification.commenter.lastName}
                 </strong>
                )}
                 </>
                )}
                
              

              <br />
              <span className="btn btn-link" onClick={() => handleNotificationClick(notification.review ? notification.review.id : notification.comment.id)}>
               View
              </span>
             </>
            )}
           </ListGroup.Item>
          ))}

          {oldReviewNotifications.concat(oldCommentNotifications).map((notification, index) => (
           <ListGroup.Item action variant="light" key={index} ref={elementRef}>
            {notification.session && notification.session.title && (
             <>
              {notification.comment ? (
               <>
                <strong>
                 {notification.commenter.firstName} {notification.commenter.lastName}
                </strong>{" "}
                commented on your review
               </>
              ) : (
               <>
                <strong>{notification.session.title}</strong> has been reviewed by {notification.reviewer.firstName} {notification.reviewer.lastName}
                {notification.commenter && " and "}
                {notification.commenter && (
                 <strong>
                  {notification.commenter.firstName} {notification.commenter.lastName}
                 </strong>
                )}
               </>
              )}
              <br />
              <span className="btn btn-link" onClick={() => handleNotificationClick(notification.review ? notification.review.id : notification.comment.id)}>
               View
              </span>
             </>
            )}
           </ListGroup.Item>
          ))}
         {currentPresenter?.role === "admin" && accountDeletionRequestnotification()}
               </ListGroup>
            
               
        )}
       </div>
      </Nav>
    </Container>
   </Navbar>
  </HeaderActivePageContext.Provider>
 );
}
