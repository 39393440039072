import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";

// Item must have properties:
// item.text (which the user sees in the dropdown, can be an element)
// item.object (which onClickFn receives)
export function DropdownWithFilter(props) {
 const { title, items, filterFn, onClickFn, className } = props;

 return (
  <Dropdown>
   <Dropdown.Toggle className={className} id="dropdown-basic">
    {title}
   </Dropdown.Toggle>
   <Dropdown.Menu className={"p-0"} style={{ zIndex: "4000" }}>
    <Form.Floating>
     <Form.Control
      id="floatingInputCustom"
      className={"border-0"}
      type={"text"}
      onChange={e => {
       const filterText = e.target.value.toLowerCase();
       filterFn(filterText);
      }}
     />
     <label htmlFor="floatingInputCustom">Search</label>
    </Form.Floating>
    {items.map((item, index) => {
     return (
      <Dropdown.Item href={"#"} key={item + index} onClick={() => onClickFn(item.object)}>
       {item.text}
      </Dropdown.Item>
     );
    })}
   </Dropdown.Menu>
  </Dropdown>
 );
}
