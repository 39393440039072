import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {useAuthContext} from "../contexts/authContext";
import { applyActionCode } from "firebase/auth";
import {fbAuth} from "../services/firebase";

export function EmailAction() {
    const [searchParams] = useSearchParams();
    const verificationCode = searchParams.get('oobCode');
    const mode = searchParams.get('mode');
    const navigate = useNavigate();
    const {user, currentPresenter, reloadAuthState} = useAuthContext();

    useEffect(() => {
        if (!user) return;
        switch (mode) {
            case 'verifyEmail':
                applyActionCode(fbAuth, verificationCode)
                    .then(async () => {
                        user.reload()
                            .then(() => {
                                reloadAuthState();
                                navigate('/dashboard');
                            });
                    });
                break;
            case 'signIn':
                navigate('/register?' + searchParams);
                break;
            case 'resetPassword':
                navigate('/login?' + searchParams);
                break;
            default:
                navigate('/');
                break;
        }
    }, [mode, navigate, searchParams, currentPresenter, user, verificationCode, reloadAuthState])
}