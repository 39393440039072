import React, { useState, useMemo, useEffect } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';   
import { Link, useParams } from 'react-router-dom';
import { useAuthContext } from "../contexts/authContext";
import { dbApi } from '../services/firebase';
import Modal  from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";


export const AccountDeletionPage = () => {
   
    const { user, currentPresenter } = useAuthContext();
    const presenterId = currentPresenter?.id;
    const [passwordConfirmation, setPasswordConfirmation] = useState(undefined);
    const [requestSent, setRequestSent] = useState(false);
    const [show, setShow] = useState(false);
    const [wrongPassword, setWrongPassword] = useState(false);
    const navigate =useNavigate()
    const handleClose = () => {
        setShow(false);
    }
      

    const handleShow = () => setShow(true);


    const handleForgotPassword = () => { navigate("/forgot-password/") }
 

    const handleDeleteAccount = () => {
        if (passwordConfirmation) {
            setRequestSent(true);
            console.log(presenterId, user.uid);
            dbApi.deleteAccountRequest(presenterId, passwordConfirmation, user)
                .catch(error => {
                    setRequestSent(false);
                    setWrongPassword(true);
                    handleShow();
                    
                    
                });
        } else {
            handleShow();
            setWrongPassword(false);
        }
    };

   
    return (
        <Container className={"container-fluid"} style={{ marginTop: "2.5rem" }}>
            <Container style={{ margin: "1em 1em 1em 1em" }}>
            <h1>Delete Account Request</h1>
            <br />
            <h2>Are you sure you want to delete your account?</h2> 
            <Link to="/dashboard/">No, take me back.</Link>
            <br />
            <br />
            <p>
                Deleting your account will result in the <strong>permanent</strong> loss of all your <strong>data</strong> and cannot be undone.
                Are you sure you want to proceed?
                <br />
                You will not be able to recover your account once it is <strong>deleted</strong>.
                <br />
                <strong>This action is irreversible!</strong>
                </p>
            </Container>
            <Card style={{ margin: "1em 1em 1em 1em" }}>
                <Card.Body>
                    <Card.Title>Confirm account deletion</Card.Title>
                    <Form.Group style={{margin:"1em 0em 1em 0em", width:"25%" }}>
                <Form.Label style={{ marginBottom: "0" }}>Confirm your password</Form.Label>
                <Form.Control 
                    className={"session-input-field"} 
                    style={{ height: "2em" }} 
                    type="password" 
                    onChange={e => setPasswordConfirmation(e.target.value)} 
                />
            </Form.Group>
                    <Button variant="danger" onClick={handleDeleteAccount}>Send request</Button>
                </Card.Body>
            </Card>
            

            {requestSent && (
                <Card style={{margin:"1em 1em 1em 1em"}}>
                    <Card.Body>
                        <Card.Title>Account deletion request sent</Card.Title>
                        <Card.Text>
                         Your account deletion request has been sent. If this was a mistake please contact support at <a href="mailto:info@re-evented.org">info@re-evented.org</a>
                        </Card.Text>
                    </Card.Body>
                </Card>
            )}
                  <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{wrongPassword ? "Wrong password." : "Empty field."}</Modal.Title>
        </Modal.Header>
                <Modal.Body>{wrongPassword ? "Wrong password, please try again." : "Please fill in your password."}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="outline-danger" onClick={handleForgotPassword}>
            Forgot password
          </Button>
        </Modal.Footer>
      </Modal>
        </Container>
    );
};
